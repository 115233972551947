export default {
	auth(to, from, next) {
		const session = this.$store.state.session;

		// if (session.loggedIn && to.name.match(/login|register/)) {
		// 	return next({ name: 'dashboard' });
		// }

		if (!session.loggedIn) {
			return next({ name: 'login' });
		}

		return true;
	},
	password_changed(to, from, next) {
		if (!this.user?.change_password_at) {
			return next({ name: 'update-password' });
		}
		return true;
	},
	profile_updated(to, from, next) {
		switch (to.params.accountType) {
			case process.env.VUE_APP_CORPORATE_PATH:
				if (!this.user?.company?.profile) {
					return next({ name: 'onboarding', params: { accountType: 'corporate' } });
				}
				break;
			case process.env.VUE_APP_CUSTOMER_PATH:
				if (!this.user.profile) {
					return next({ name: 'settings-profile', params: { accountType: 'customer' } });
				}
				break;
			case process.env.VUE_APP_ADMIN_PATH:
				break;
			default:

				break;
		}

		to, from, next;

		return true;
	},
	profile_approved(to, from, next) {
		switch (to.params.accountType) {
			case process.env.VUE_APP_CORPORATE_PATH:
				if (this.user?.company?.profile !== 'approved') {
					// return next({ name: 'awaiting-approval', params: { accountType: 'corporate' } });
				}
				break;
			case process.env.VUE_APP_CUSTOMER_PATH:
				if (this.user.profile?.status !== 'activated') {
					return next({ name: 'awaiting-approval', params: { accountType: 'customer' } });
				}
				break;
			case process.env.VUE_APP_ADMIN_PATH:
				break;
			default:

				break;
		}

		to, from, next;

		return true;
	},
	company_subscribed(to, from, next) {
		if (to.params.accountType == process.env.VUE_APP_CORPORATE_PATH) {
			if (!this.user?.company?.company_unique_plan) {
				return next({ name: 'subscription', params: { accountType: process.env.VUE_APP_CORPORATE_PATH } });
			}
		}

		return true;
	},
	permissions(to, from, next) {
		if (!this.canAccess(to.name)) {
			console.log('cannot access ' + to.name)
			return next({ name: 'root', query: { accountType: to.params.accountType } });
		}

		return true;
	},
	account_type(to, from, next) {
		const requiredAccountType = to.params.accountType;

		switch (requiredAccountType) {
			case process.env.VUE_APP_ADMIN_PATH:
				if (!this.isCredpalAdmin) {
					return next({ name: 'errors-404-main', query: { permission: false } });
				}
				break;
			case process.env.VUE_APP_CORPORATE_PATH:
				if (!this.isCompanyAdmin) {
					return next({ name: 'errors-404-main', query: { permission: false } });
				}
				break;
			case process.env.VUE_APP_CUSTOMER_PATH:
				if (!this.isCompanyStaff) {
					return next({ name: 'errors-404-main', query: { permission: false } });
				}
				break;
			default:
				break;
		}

		return true;
	},
	// super_admin(to, from, next) {

	// 	if (!this.role.match(/super_(admin|sales_agent)/)) {
	// 		return next({ name: 'dashboard' });
	// 	}
		
	// 	return true;
	// }
};