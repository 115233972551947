import Vue from 'vue';
import Pusher from 'pusher-js';
import Raphael from 'raphael/raphael';
import App from './App.vue';
import router from './router';
import store from './store';
import events from './config/events';
// import config from '../config' ;
import './components';
import './mixins';
import './assets/sass/app.scss';
import './javascript';
import './slider';

global.Raphael = Raphael;

Vue.config.ignoredElements = [/^ion-/];
Vue.config.productionTip = false;
Vue.config.devtools = true;

Pusher.logToConsole = false;
// const {APP_KEY, APP_CLUSTER} = config;
// const pusher  = new Pusher(APP_KEY, {
//     cluster: APP_CLUSTER,
// });
// pusher.connection.bind( 'error', function( err ) {
//     if( err.error.data.code === 4004 ) {
//         console.log('Over limit!');
//     }
// });

// Vue.prototype.pusher = pusher;
Vue.prototype.$baseurl = process.env.VUE_APP_API_BASEURL;
Vue.prototype.$frontEndBaseurl =  process.env.VUE_APP_URL; 
// "https://credpal.com";
// const testUrl = 'http://corporate-loans.test/api';
// const testUrl = 'https://corp-loans-staging.credpal.com/api';
// const testUrl = 'http://192.168.0.20:8082/api';
// const testUrl = 'http://54.152.153.27/api';
// const testUrl = 'https://corp-loans.credpal.com/api';
// Vue.prototype.$baseurl = "https://corp-loans.credpal.com/api";

// switch (process.env.NODE_ENV) {
// switch (process.env.VUE_APP_ENV ) {
//     case 'production':
//         // if (window.location.host == 'affiliate.credpal.com') {
//         //     Vue.prototype.$baseurl = 'https://corp-loans.credpal.com/api';
//         // } else if (window.location.host == 'www.affiliate.credpal.com') {
//         //     Vue.prototype.$baseurl = 'https://corp-loans.credpal.com/api';
//         // }
//         // else {
//         //     // Vue.prototype.$baseurl = 'http://18.206.190.231/api';
//         //     Vue.prototype.$baseurl = 'https://corp-loans-staging.credpal.com/api';
//         // }
//         Vue.prototype.$baseurl = 'https://corp-loans.credpal.com/api';
//         Vue.prototype.$frontEndBaseurl = "https://credpal.com";
//         break;
//     case 'develop':
//         Vue.prototype.$baseurl = 'https://api.dev.credpal.com/api';
//         Vue.prototype.$frontEndBaseurl = "https://dev.corporate.credpal.com";
//         break;
//     case 'test':
//         Vue.prototype.$baseurl = Vue.prototype.$baseurl = "https://api.test.credpal.com/api";
//         Vue.prototype.$frontEndBaseurl = "https://test.corporate.credpal.com";
//         break
//     default:
//         Vue.prototype.$baseurl = "https://corp-loans.credpal.com/api";
//         Vue.prototype.$frontEndBaseurl = "https://credpal.com";
//         // Vue.prototype.$frontEndBaseurl = "https://test.corporate.credpal.com";
//         break;
// }

import {
    mapState,
    mapActions,
    mapMutations
} from 'vuex';

window.App = new Vue({
    router,
    store,
    render: h => h(App),
    data() {
        return {
            sessionInterval: null
        }
    },
    computed: {
        ...mapState('design', ['sidebar']),
        ...mapState('session', ['expires_at', 'loggedIn']),
    },
    beforeCreate() {
        this.$store.dispatch('session/loadUser');
        this.$store.dispatch('affiliate/setAffiliateCode');
        // this.$store.dispatch('modals/loadModals');
    },
    async mounted() {
        this.checkSessionExpiry();
        await this.loadSession();
        this.ready();
        this.setInterval();
        this.$root.$on('login', () => {
            this.setInterval();

            this.reloadIntercom(this.$store.state.session.user);

            // this.$store.dispatch('resources/fetchAll');
        });

        this.$root.$on('global', (data) => {
            events[data.event]?.apply(this, data.params);
        });
    },
    methods: {
        ...mapActions('session', ['logout', 'updateUser']),
        ...mapMutations('design', ['closeSidebar']),
        ...mapMutations('session', ['ready']),

        checkSessionExpiry() {
            const time = this.expires_at;
            if (time) {
                const now = (new Date()).getTime();

                if (now >= time) {
                    clearInterval(this.sessionInterval);

                    this.logout();
                    if (this.$route.name !== 'login') {
                        this.$router.push({
                            name: 'login',
                            query: {
                                expired: true
                            }
                        });
                    }
                }
            }

        },
        reloadIntercom(user) {
            window.intercomSettings = {
                ...window.intercomSettings,
                name: user.name,
                email: user.email,
                created_at: user.created_at
            };

            if (window.Intercom) {
                window.Intercom('update', window.intercomSettings);
            }
        },
        async loadSession() {
            if (this.loggedIn) {
                await this.$get({
                    url: `${this.$baseurl}/affiliate/session`,
                    headers: this.headers,
                    success: async (response) => {
                        this.updateUser(response.data.user);
                        this.$root.$emit('global', {
                            event: 'sessionUpdate',
                            params: [response.data.user]
                        });

                        if (response.data.keys) {
                            this.$store.commit('session/updateKeys', response.data.keys);
                            this.$store.commit('session/updatePermissions', response.data.permissions?.map(permission => permission.slug));
                            this.$store.commit('session/updateRoles', response.data.roles);
                            this.$store.commit('session/updateCorporateCardCount', response.data.corporate_card_count);
                        }

                        this.reloadIntercom(response.data.user);

                        const modals = response.data.closed_modals;
                        if (modals && modals.length) {
                            modals.forEach(modal => {
                                this.$store.commit('modals/closeModal', {
                                    modal: modal.key
                                });
                            });
                        }

                        // this.$store.dispatch('resources/fetchAll', this);
                    },
                    error: error => {
                        this.logout();
                        console.log('Logged out');
                        console.log(error);
                    }
                });
            }
        },
        setInterval() {
            this.sessionInterval = setInterval(this.checkSessionExpiry, 1000);
        }
    }
}).$mount('#app');
