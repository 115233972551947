<template>
  <div class="auth h-full">
    <nav class="flex justify-between md:px-6 py-5">
      <div class="flex -ml-2">
        <router-link
          class="btn bg-white focus:border-white w-56 -ml-4 lg:-ml-8"
          style="color: #274FED;"
          :to="{ name: 'home' }"
        >
          <img :src="logo" alt="CredPal" class="" />
        </router-link>
        <div
          class="text-xs text-white bg-gray-500 py-1 px-3 w-auto -ml-10 self-start rounded"
        >
          Referrals
        </div>
      </div>
      
        <div class="font-normal mr-6 lg:mr-4 flex">
          <template v-if="!checkAuth">
            <span class="hidden md:block mr-2">Have an account?</span>
            <router-link
              class="focus:border-white font-bold mt-4 md:mt-0"
              :to="{ name: 'login' }"
            >
              Sign In
            </router-link>
          </template>
          <a v-else href="#!" class="text-red-500" @click.prevent="logout">
						<!-- <ion-icon name="log-out-outline" class="icon tx-sm"></ion-icon> -->
						<span class="text">Log Out</span>
					</a>
        </div>
    </nav>
    <div class="h-full">
      <div class="flex m-auto">
        <div class="w-full md:px-20 flex-grow flex items-center justify-center">
          <div class="mb-32">
            <!-- Router View -->
            <transition name="fade" mode="out-in">
              <router-view />
            </transition>
            <!-- Router View -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clipPayment: require("@/assets/clip-payment.svg"),
      logo: require("@/assets/logo-blue.svg"),
    };
  },
  computed: {
    checkAuth() {
      return this.$store.state.session.loggedIn;
    },
  },
  methods: {
    logout() {
				this.$store.dispatch('session/logout');
				this.$router.push({ name: 'login' });
		},
  },
};
</script>
