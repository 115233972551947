<template>
	<div class="flex mb-32">

		<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

			<h1 class="text-2xl font-bold mb-5">User Onboarding</h1>

			<div class="card border border-blue-200 pt-16 pb-10 px-6 md:px-16 -mx-6 sm:mx-0">

				<!-- <multiple /> -->

				<!-- <div class="my-16 border-t border-blue-200"></div> -->

				<single />
				
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		components: {
			single: require('./Single').default,
			// multiple: require('./Multiple').default,
		},
		data() {
			return {
				modalKey: 'staff-new-help',
				tab: 'single',
				tabs: [
					{name: 'single', title: 'Add an Employee'},
					{name: 'multiple', title: 'Add Multiple Employees'},
				],
			}
		},
		computed: {
		},
		beforeMount() {
		},
		mounted() {
			this.openModal(this.modalKey, this.$refs.helpModal);
		},
		watch: {
		},
		methods: {
		}
	}
</script>