export default {
  loggedIn(user) {
    console.log(user);
  },
  sessionUpdate(user) {
    console.log(user);
  },
  navigate(to) {
    const accountType = to.params.accountType

    if (accountType) {
      this.$store.dispatch('resources/fetchAll', accountType);
    }

  }
}