import Vue from 'vue';

Vue.mixin({
  data() {
    return {
      assets: {
        icons: {
          sm: {
            bills: require('@/assets/icons/sm/bills.svg'),
            cable: require('@/assets/icons/sm/cable.svg'),
            insurance: require('@/assets/icons/sm/insurance.svg'),
            internet: require('@/assets/icons/sm/internet.svg'),
            flight: require('@/assets/icons/sm/flight.svg'),
            lightbulb: require('@/assets/icons/sm/lightbulb.svg'),
            mobile_top_up: require('@/assets/icons/sm/mobile-top-up.svg'),
          },
          lg: {
            checkmark: require('@/assets/checkmark-base.svg'),
            exclamation: require('@/assets/exclamation.svg'),
          }
        }
      }
    }
  },
  computed: {
    isBigScreen() {
      return window.innerWidth > 768
    },
    isSmallScreen() {
      return window.innerWidth <= 768
    },
  }
});