<template>
  <div class="flex flex-wrap mb-4">
    <div class="w-full px-10 mt-10">
      <h2 class="font-bold text-3xl mb-10">Processing Withdrawals</h2>
      <!-- <div class="">
        <div class="flex justify-between">
          <div class="flex flex-wrap md:flex-no-wrap mt-6">
            <div class="filter-date md:mr-6">
              <form-groupd
                type="date"
                rightIcon="calendar-outline"
                name="date_of_birth"
                className="filter-date"
              >
                From
              </form-groupd>
            </div>

            <div class="filter-date">
              <form-groupd
                type="date"
                rightIcon="calendar-outline"
                name="date_of_birth"
                className="filter-date"
              >
                to
              </form-groupd>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="w-full px-10">
      <div class="border border-solid border-blue-200">
        <datatable
          :data="rewards.data"
          :columns="table.columns"
          :query="query"
          :loading="rewards.loading"
          ref="table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      table: this.$options.resource([], {
        columns: [
          {
            name: "batch",
            th: "Reference Number",
          },
          {
            name: "request_date",
            th: "Request Date",
            render: (reward) => {
              if (!reward.request_date) {
                return '---'
              }
              return moment(reward.request_date).format("MMM Do, YYYY h:mm:ss a");
            },
          },
          {
            name: "commission",
            th: "Commission Amount",
            render: (reward) => {
              return this.$options.filters.currency(reward.commission || 0);
            },
          },
          {
            name: "paid_date",
            th: "Paid Date",
            render: (reward) => {
              if (!reward.paid_date) {
                return '---'
              }
              return moment(reward.paid_date).format("MMM Do, YYYY h:mm:ss a");
            },
          },
          {
            name: "status",
            th: "Status",
            render: (reward) => {
              const status = reward?.paid_date ? "paid" : "processing";
              switch (status) {
                case "paid":
                  return `<div class="badge badge-green-soft-outline">
											Paid
										</div>`;
                case "processing":
                default:
                  return `<div class="badge badge-gray-soft-outline">
											Processing
										</div>`;
              }
            },
          },
        ],
        selected: null,
      }),
      rewards: this.$options.resource([], {
        loading: false,
      }),
      config: this.$options.resource([]),
      query: null,
    };
  },
  computed: {},
  async beforeMount() {
    this.getDisbursedRewards();
    this.getProcessingRewards();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    async getDisbursedRewards() {
      this.rewards.loading = true;
      await this.sendRequest("affiliate.rewards.disbursed", {
        success: (response) => {
          const data = response.data.data;
          if (data) {
            this.rewards.data = Object.values(data);
          }
        },
        error: (error) => {
          console.log(44, error);
          this.rewards.error = error;
        },
      });
      this.rewards.loading = false;
    },
    async getProcessingRewards() {
      this.rewards.loading = true;
      await this.sendRequest("affiliate.rewards.processing", {
        success: (response) => {
          const data = response.data.data;
          if (data) {
            this.rewards.data = Object.values(data);
          }
        },
        error: (error) => {
          this.rewards.error = error;
        },
      });
      this.rewards.loading = false;
    },
  },
};
</script>
