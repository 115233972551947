<template>
	<div>
		<div class="form-group" :class="{ active: isActive, focused: isFocused, 'no-left-icon': leftIcon === false, 'no-right-icon': rightIcon === false, 'textarea': isTextArea }">

			<label for="" class="label">
				<slot />
			</label>

			<template v-if="loading">
				<input
					type="text"
					class="input"
                    :class="className"
					readonly
					ref="input"
					@focus="focus"
					@blur="blur"
				>
				<div class="input-loading">
					<div class="dot"></div>
					<div class="dot"></div>
					<div class="dot"></div>
				</div>
				<span
					class="loader-icon icon icon-left"
					v-if="leftIcon !== false"
				></span>
				<span
					class="loader-icon icon icon-right"
					v-if="rightIcon !== false"
				></span>
			</template>
			<template v-else>
				<input
					:type="showPassword ? 'text' : 'password'"
					:name="name"
					class="input"
					v-model="inputValue"
					@focus="focus"
					@blur="blur"
					@input="change"
					:disabled="isDisabled"
					:autofocus="autofocus"
					v-if="isPassword"
					ref="input"
				>

				<money
					class="input"
					v-model="inputValue"
					:precision="0"
					@focus="focus"
					@blur="blur"
					@change="change"
					:disabled="isDisabled"
					:autofocus="autofocus"
					v-else-if="isMoney"
					ref="input"
				/>

				<datepicker
					:value="inputValue"
					class="input"
					:precision="0"
					@focus="focus"
					@blur="blur"
					@input="change"
					:disabled="isDisabled"
					:autofocus="autofocus"
					v-else-if="isDate"
					ref="input"
				/>

				<custom-select
					class="input"
					:name="name"
					v-model="inputValue"
					@focus="focus"
					@blur="blur"
					@change="change"
					:disabled="isDisabled"
					:autofocus="autofocus"
					:options="options"
					:display-type="selectDisplayType"
					:grid-columns="selectGridColumns"
					v-else-if="isSelect"
					ref="input"
				/>

				<textarea
					class="input"
					:name="name"
					v-model="inputValue"
					@focus="focus"
					@blur="blur"
					@input="change"
					:disabled="isDisabled"
					:autofocus="autofocus"
					v-else-if="isTextArea"
					ref="input"
				></textarea>

				<template v-else-if="isFile">
					<label class="input cursor-pointer">
						{{ inputValue ? inputValue.name : 'click to select file' }}
						<input
							:type="type"
							class="input-file"
							:name="name"
							@focus="focus"
							@blur="blur"
							@change="change"
							:disabled="isDisabled"
							:autofocus="autofocus"
							ref="input"
						>
					</label>
				</template>

				<input
					:type="type"
					class="input"
					:name="name"
					v-model="inputValue"
					@focus="focus"
					@blur="blur"
					@input="change"
					:disabled="isDisabled"
					:autofocus="autofocus"
					v-else
					ref="input"
				>

				<ion-icon
					class="icon icon-left"
					:name="leftIcon"
					v-if="leftIcon !== false"
				></ion-icon>
				<ion-icon
					class="icon icon-right"
					:name="rightIcon"
					v-if="rightIcon !== false"
				></ion-icon>
				
			</template>

			<ion-icon
				class="icon icon-right cursor-pointer text-gray-400"
				:name="rightDefaultIcon"
				v-if="isPassword"
				@click.prevent="togglePassword"
			></ion-icon>
			<span
				class="icon icon-right caret text-gray-400"
				:name="rightDefaultIcon"
				v-else-if="isSelect"
			></span>
			<ion-icon
				class="icon icon-right cursor-pointer text-gray-400"
				:name="rightIcon"
				v-else-if="rightIcon"
				@click.prevent="rightIconClick"
			></ion-icon>
			<img :src="rightIconImg" class="icon icon-right" :alt="rightIconImgAlt" v-else-if="rightIconImg">

		</div>
		
		<div class="form-group-text" :class="textColor" v-if="firstError">{{ firstError }}</div>
	</div>
</template>

<script>
	import { Money } from "v-money";

	export default {
		components: {
			Money
		},
		model: {
			prop: 'value',
			event: 'input'
		},
		props: {
            className: {
                type: String,
                default: "null"
            },
			autofocus: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			},
			form: {
				type: Object,
				default: null
			},
			id: {
				type: String,
				default: null
			},
			leftIcon: {
				type: [String, Boolean],
				default: ''
			},
			// rightIcon: {
			// 	type: [String, Boolean],
			// 	default: ''
			// },
			loading: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: null
			},
			options: {
				type: Array,
				default: () => []
			},
			rightIcon: {
				type: [String, Boolean],
				default: false
			},
			rightIconImg: {
				type: String,
				default: null
			},
			rightIconImgAlt: {
				type: String,
				default: null
			},
			rightIconClick: {
				type: Function,
				default: () => {}
			},
			selectDisplayType: {
				type: String,
				default: 'list'
			},
			selectGridColumns: {
				type: Number,
				default: 4
			},
			type: {
				type: String,
				default: 'text'
			},
			under: {
				type: String,
				default: ''
			},
			value: {
				type: [String, Number, File],
				default: ''
			},
		},
		data() {
			return {
				isFocused: false,
				showPassword: false,
				inputValue: ''
			}
		},
		computed: {
			errors() {
				return this.field?.errors;
			},
			field() {
				return this.data ? this.data : this.form && this.name && this.form.data[this.name] ? this.form.data[this.name] : null;
			},
			firstError() {
				if (this.errors) {
					const key = Object.keys(this.errors)[0];
					switch (key) {
						case 'same':
							return 'The passwords do not match';
						default:
							return this.errors[key];
					}
				}else {
					return null;
				}
			},
			isActive() {
				return this.isFocused || this.inputValue?.toString()?.length;// || this.isDate;
			},
			isDate() {
				return this.type && this.type.match('date');
			},
			isDisabled() {
				return this.disabled || this.loading;
			},
			isFile() {
				return this.type && this.type.match('file');
			},
			isPassword() {
				return this.type && this.type.match('password');
			},
			isMoney() {
				return this.type && this.type.match('money');
			},
			isSelect() {
				return this.type && this.type.match('select');
			},
			isTextArea() {
				return this.type && this.type.match('textarea');
			},
			rightDefaultIcon() {
				return this.showPassword ? 'eye-off-outline' : 'eye-outline';
			},
			textColor() {
				if (this.errors) {
					return 'text-red-500';
				}
				return '';
			}
		},
		mounted() {
			this.inputValue = this.value;

			this.$el.addEventListener('mousedown', () => {
				setTimeout(() => {
					if (this.isMoney) {
						this.$refs.input.$el.focus();
					} else if (this.isDate) {
						// this.$refs.input.$el.querySelector('.vdp-datepicker.input input').focus()
						// this.$refs.input.$el.querySelector('.vdp-datepicker.input input').click()
					}else {
						this.$refs.input.focus();
					}
				}, 1);
			}, true);

			if (this.isMoney || this.isDate) {
				this.$el.addEventListener('input', () => {
					this.change();
				}, true);

				this.$el.addEventListener('focus', () => {
					this.focus();
				}, true);

				this.$el.addEventListener('blur', () => {
					this.blur();
				}, true);
			}

			// if (this.isDate) {
			// 	this.$refs.input
			// }
		},
		watch: {
			value() {
				this.inputValue = this.value;
			}
		},
		methods: {
			blur(){
				this.isFocused = false;
			},
			change(event){
				if (this.isFile) {
					this.inputValue = event.target.files[0];
				}else if (this.isDate) {
					this.inputValue = `${event.getFullYear()}-${event.getMonth()+1}-${event.getDate()}`
				}

				this.$emit('input', this.inputValue);
				this.validateData();
			},
			focus(){
				this.isFocused = true;
			},
			togglePassword() {
				this.showPassword = !this.showPassword;
				this.$refs.input.focus();
			},
			validateData() {
				if (this.name && this.field) {

					var input;

					if (this.isMoney) {
						input = this.$refs.input.$el;
					}else {
						input = this.$refs.input;
					}

					this.validateField(this.name, this.field, input, this.form);
				}
			}
		}
	}
</script>