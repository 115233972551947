<template>
  <div class="flex flex-wrap mb-4">
    <div class="w-full px-10 mt-10">
      <h2 class="font-bold text-3xl mb-10">Daily Summary</h2>
      <!-- <div class="">
        <div class="flex justify-between">
          <div class="flex flex-wrap md:flex-no-wrap mt-6">
            <div class="filter-date md:mr-6">
              <form-groupd
                type="date"
                rightIcon="calendar-outline"
                name="date_of_birth"
                className="filter-date"
              >
                From
              </form-groupd>
            </div>

            <div class="filter-date">
              <form-groupd
                type="date"
                rightIcon="calendar-outline"
                name="date_of_birth"
                className="filter-date"
              >
                to
              </form-groupd>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="w-full px-10">
      <div class="border border-solid border-blue-200">
      <!-- <div class=""> -->
        <datatable
          :data="dailySummary.data"
          :columns="table.columns"
          :query="query"
          :loading="dailySummary.loading"
          ref="table"
        />
        <!-- <datatable
          :data="dailySummary.data"
          :columns="table.columns"
          :paginatable="false"
          :loading="dailySummary.loading"
          :showHeadings="true"
          :fillable="false"
          table-style="flush"
          ref="table"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      table: this.$options.resource([], {
        columns: [
          {
            name: "date",
            th: "Date",
            render: (summary) => {
              return moment(summary.date).format("MMM Do, YYYY");
            },
          },
          {
            name: "totalClicks",
            th: "Total Clicks",
            render: (value) => {
              if (!value["totalClicks"]) {
                return 0;
              }
              return value["totalClicks"];
            },
          },
          {
            name: "uniqueClicks",
            th: "Unique Clicks",
            render: (value) => {
              if (!value["uniqueClicks"]) {
                return 0;
              }
              return value["uniqueClicks"];
            },
          },
          {
            name: "pending_conversions",
            th: "Pending Conversions",
            render: (value) => {
              if (!value["pending_conversions"]) {
                return 0;
              }
              return value["pending_conversions"];
            },
          },
          {
            name: "confirmedConversions",
            th: "Confirmed Conversions",
            render: (value) => {
              if (!value["confirmed_conversions"]) {
                return 0;
              }
              return value["confirmed_conversions"];
            },
          },
          {
            name: "rejectedConversions",
            th: "Rejected Conversions",
            render: (value) => {
              if (!value["rejectedConversions"]) {
                return 0;
              }
              return value["rejectedConversions"];
            },
          },
        ],
        selected: null,
      }),
      dailySummary: this.$options.resource([], {
        loading: false,
      }),
      config: this.$options.resource([]),
      query: null,
      loading: false,
    };
  },
  computed: {},
  async beforeMount() {
    this.getDailySummary();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    async getDailySummary() {
      this.dailySummary.loading = true;
      await this.sendRequest("affiliate.conversions.all", {
        success: (response) => {
          const { data } = response.data;
          const keys = Object.keys(data);
          let arr = [];
          keys.forEach((day) => {
            arr.push({ date: day, ...data[day] });
          });

          this.dailySummary.data = arr;
        },
        error: (error) => {
          this.dailySummary.error = error;
        },
      });
      this.dailySummary.loading = false;
    },
  },
};
</script>
