<template>
	<div class="custom-chart custom-bar-chart">
		<div class="custom-chart-content" :style="{ height: chartHeight }">

			<div class="grid">
				<div class="grid-lines grid-lines-horizontal">
					<template v-for="(line, n) in gridXCount + 1">
						<div class="grid-line" :key="n">
							<div class="grid-line-label">{{ gridXLabelPrefix }}{{ getOffset(n) }}{{ gridXLabelSuffix }}</div>
						</div>
					</template>
				</div>
				<div class="grid-lines grid-lines-vertical">
					<template v-for="(line, n) in gridYCount">
						<div class="grid-line" :key="n"></div>
					</template>
				</div>
			</div>

			<div class="bars">
				<template v-for="(column, n) in columns">
					<div class="bar" :key="n" @click="clicked(column)">
						<div class="bar-visual" :style="{ height: `${getPercentage(column)}%` }">
							<div class="bar-tip">
								<template v-if="tooltipFormat" >
									<div v-html="tooltipFormat(column)"></div>
								</template>
								<template v-else>
									<div class="bar-tip-title">{{ column.label }}</div>
									<div class="bar-tip-value">{{ getColumnValue(column) }}</div>
								</template>

							</div>
						</div>
						<div class="bar-label">{{ column.label }}</div>
					</div>
				</template>

			</div>

		</div>
	</div>
</template>

<script>
	export default {
		props: {
			grid: {
				type: Array,
				default: () => [5, 10]
			},
			height: {
				type: [Number, String],
				default: '300'
			},
			columns: {
				type: Array,
				default: () => []
			},
			onClick: {
				type: Function,
				default: () => {}
			},
			gridXLabelFormat: {
				type: Function,
				default: null
			},
			gridXLabelPrefix: {
				type: String,
				default: ''
			},
			gridXLabelSuffix: {
				type: String,
				default: ''
			},
			maxValue: {
				type: Number,
				default: null
			},
			tooltipFormat: {
				type: Function,
				default: null
			},
			valueFormat: {
				type: Function,
				default: null
			}
		},
		computed: {
			gridXCount() {
				return this.grid[0];
			},
			gridYCount() {
				return this.grid[1] || 0;
			},
			chartHeight() {
				switch (this.height.constructor) {
					case String:
						return this.height.match('(%|px|pc|rem|em)$') ? this.height : `${this.height}px`;
					case Number:
						return `${this.height}px`;
				}

				return this.height;
			},
			max() {

				if (this.maxValue) {
					return this.maxValue;
				}

				var max = 0;
				this.columns.forEach(column => {
					max = max >= column.value ? max : column.value;
				});
				return max;
			},
			min() {
				var min = Infinity;
				this.columns.forEach(column => {
					min = min <= column.value ? min : column.value;
				});
				return min === Infinity ? 0 : min;
			}
		},
		methods: {
			clicked(column) {
				this.onClick(column);
			},
			getColumnValue(column) {
				if (this.valueFormat) {
					return this.valueFormat(column);
				}

				return column.value;
			},
			getPercentage(column) {
				return column.value == 0 ? 0 : column.value / this.max * 100;
			},
			getOffset(position) {
				var offset = position / this.gridXCount * this.max;

				if (this.gridXLabelFormat) {
					return this.gridXLabelFormat(offset);
				}
				return offset;
			}
		}
	}
</script>