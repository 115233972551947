import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';


Date.prototype.format = function(format) {
	this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	this.days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	this.formatKeys = {
		y: () => this.getYear(),
		Y: () => this.getFullYear(),
		mm: () => {
			var month = this.getMonth() + 1;
			return month >= 10 ? month : `0${month}`;
		},
		m: () => this.getMonth() + 1,
		MM: () => this.months[this.getMonth()],
		M: () => this.months[this.getMonth()].slice(0, 3),
		dd: () => this.getDate(),
		d: () => this.getDay(),
		DD: () => this.days[this.getDay()],
		D: () => this.days[this.getDay()].slice(0, 3),
		hh: () => {
			var hours = this.formatKeys.h;
			return hours > 9 ? hours : `0${hours}`;
		},
		h: () => {
			var hours = this.getHours();
			return hours == 12 || hours == 0 ? 12 : hours % 12;
		},
		H: () => this.getHours(),
		i: () => {
			var mins = this.getMinutes();
			return mins >= 10 ? mins : `0${mins}`;
		},
		s: () => {
			var secs = this.getSeconds();
			return secs >= 10 ? secs : `0${secs}`;
		},
		a: () => this.getHours() >= 12 ? 'pm' : 'am',
		A: () => this.getHours() >= 12 ? 'PM' : 'AM',
	};
	return format.replace(/y|Y|mm|m|MM|M|dd|d|DD|D|hh|h|H|i|s|a|A/g, (key) => {
		return this.formatKeys[key].apply(this);
	})
}

String.prototype.fromSlug = function() {
	return this.replace(/_|-/g, ' ').replace(/^\w|\s\w/g, char => char.toUpperCase());
}

String.prototype.toPosition = function() {
	const value = this;
	if (value.match(/11$/) || value.match(/12$/) || value.match(/13$/)) {
		return `${value}th`;
	}else if (value.match(/1$/)) {
		return `${value}st`;
	}else if (value.match(/2$/)) {
		return `${value}nd`;
	}else if (value.match(/3$/)) {
		return `${value}rd`;
	}else {
		return `${value}th`;
	}
}

Number.prototype.toPosition = function() {
	return this.toString().toPosition();
}

document.body.addEventListener('click', event => {
	if (event.target.matches('[dropdown], [dropdown] *')) {
		event.preventDefault();
		event.stopPropagation();

		var boundary;

		let target = event.target;
		while(!target.matches('[dropdown]')) {
			target = target.parentElement;
		}

		const parent = target.parentElement;
		const dropdown = parent.querySelector('.dropdown-menu');

		boundary = $(dropdown).closest($(dropdown).data('boundary'))[0];

		if (!boundary) {
			boundary = window;
		}

		const dropdowns = document.body.querySelectorAll('.dropdown');
		dropdowns.forEach(dd => {
			if (dd !== parent) {
				dd.classList.remove('open');
			}
		});

		if (parent.matches('.open')) {
			$(parent).removeClass('open');
			setTimeout(() => {
				$(dropdown).removeClass('top bottom');
			}, 300);
		}else {

			const minX = $(dropdown).offset().left;
			const minY = $(dropdown).offset().top;
			const maxX = minX + dropdown.offsetWidth;
			const maxY = minY + dropdown.offsetHeight;
			const windowHeight = (boundary === window ? 0 : $(boundary).offset().top) + $(boundary).outerHeight();
			const windowWidth = (boundary === window ? 0 : $(boundary).offset().left) + $(boundary).outerWidth();

			// Max boundary height should be either window or selected boundary depending on which is smaller

			if (maxX > windowWidth) {
				dropdown.classList.add('left');
				// dropdown.classList.remove('right');
			}else {
				// dropdown.classList.remove('left');
				dropdown.classList.add('right');
			}

			if (maxY > windowHeight) {
				dropdown.classList.remove('bottom');
				dropdown.classList.add('top');
			}else {
				dropdown.classList.remove('top');
				dropdown.classList.add('bottom');
			}

			setTimeout(() => {
				parent.classList.add('open');
			}, 10);
		}

	}else if (event.target.matches('[data-toggle="sidebar"], [data-toggle="sidebar"] *')) {
		event.preventDefault();

		const body = document.body;
		if (body.classList.contains('sidebar-open')) {
			body.classList.remove('sidebar-open');
		}else {
			body.classList.add('sidebar-open');
		}
	}
});

window.addEventListener('click', event => {
	if (!event.target.matches('.dropdown-menu, .dropdown-menu *')) {
		const dropdowns = document.body.querySelectorAll('.dropdown');
		dropdowns.forEach(dropdown => {
			dropdown.classList.remove('open');
		});
	}
});

function collapse(selector, action = 'toggle') {
	
	switch (action) {
		case 'show':
			$(selector).slideDown().addClass('collapse-open');
			break;
		case 'hide':
			$(selector).slideUp().removeClass('collapse-open');
			break;
		case 'toggle':
		default:
			if ($(selector).is('.collapse-open')) {
				collapse(selector, 'hide');
			}else {
				collapse(selector, 'show');
			}
			break;
	}


}

window.collapse = collapse;


$.fn.resizeText = async function(value) {
	const element = this;
	const parent = $(element).parent();

	element.css({
		'font-size': 0,
		'white-space': 'nowrap'
	});
	await new Promise(resolve => setTimeout(resolve, .1));

	const parentWidth = parent.outerWidth();
	const percentage = value / 100 * parentWidth;
	// const textLength = element.text().length;

	while (element.width() < percentage) {
		element.css('font-size', parseInt(element.css('font-size'), 10) + 1);
	}
}

$(window).on('load resize', () => {
	$('[data-fit]').each( (index, element) => {
		$(element).resizeText($(element).data('fit'));
	} );
});

// $('[data-fit]').on('custom.update', function() {
// 	console.log('updated');
// 	$(this).resizeText($(this).data('fit'));
// })