import Vue from "vue";

Vue.mixin({
  data() {
    return {
      apiRequests: {
        affiliate: {
          conversions: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/clicks-conversions`,
                headers: this.headers,
                ...config,
              });
            },
            async customers(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/customers`,
                headers: this.headers,
                ...config,
              });
            },
          },
          rewards: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/rewards`,
                headers: this.headers,
                ...config,
              });
            },
            async rewards(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/rewards/all-batch-request-to-withdraw`,
                headers: this.headers,
                ...config,
              });
            },
            async disbursed(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/rewards/all-batch-request-to-withdraw?status=disbursed`,
                headers: this.headers,
                ...config,
              });
            },
            async processing(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/rewards/all-batch-request-to-withdraw?status=processing`,
                headers: this.headers,
                ...config,
              });
            },
            async total(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/rewards/summary`,
                headers: this.headers,
                ...config,
              });
            },
          },
          referralCodes: {
            async codes(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/code`,
                headers: this.headers,
                ...config,
              });
            },
          },
          payment: {
            async getBankDetails(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/affiliate/profile/payment/details`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/affiliate/profile/payment/details`,
                headers: this.headers,
                ...config,
              });
            },
          },
        },
        admin: {
          cards: {
            corporate: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/corporate/cards`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
            personal: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/cards`,
                  headers: this.headers,
                  ...config,
                });
              },
              async requests(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/personal/cards/all`,
                  headers: this.headers,
                  ...config,
                });
              },
              async approve(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/card/approve`,
                  headers: this.headers,
                  ...config,
                });
              },
              async decline(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/card/decline`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
          },
          companies: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies`,
                headers: this.headers,
                ...config,
              });
            },
            async requests(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/requests`,
                headers: this.headers,
                ...config,
              });
            },
            creditApplications: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/card/requests/all`,
                  headers: this.headers,
                  ...config,
                });
              },
              async approve(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/wallet/set-wallet-limit`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
          },
          users: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/users`,
                headers: this.headers,
                ...config,
              });
            },
            async approve(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/activate`,
                headers: this.headers,
                ...config,
              });
            },
            async decline(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/deactivate`,
                headers: this.headers,
                ...config,
              });
            },
            creditApplications: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/credit-applications`,
                  headers: this.headers,
                  ...config,
                });
              },
              async approve(application_id, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/credit-applications/approve/${application_id}`,
                  headers: this.headers,
                  ...config,
                });
              },
              async decline(application_id, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/credit-applications/decline/${application_id}`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
          },
          loans: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/loans`,
                headers: this.headers,
                ...config,
              });
            },
            async requests(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/loans/pending`,
                headers: this.headers,
                ...config,
              });
            },
            async approved(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/loans/approved`,
                headers: this.headers,
                ...config,
              });
            },
            async approve(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/loans/approve`,
                headers: this.headers,
                ...config,
              });
            },
            async disburse_many(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/loans/disburse-many`,
                headers: this.headers,
                ...config,
              });
            },
            async disbursed(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/loans/disbursed`,
                headers: this.headers,
                ...config,
              });
            },
            async undisburse_many(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/loans/undisburse-many`,
                headers: this.headers,
                ...config,
              });
            },
          },
        },
        corporate: {
          creditApplication: {
            async backendCheck(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditLimit/request`,
                headers: this.headers,
                ...config,
              });
            },
            async submitApplication(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditLimit/request/final`,
                headers: this.headers,
                ...config,
              });
            },
            async documents(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/documents/upload`,
                headers: this.headers,
                ...config,
              });
            },
          },
          subscription: {
            async getActivePlan(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/plan/unique-plan/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async getPlans(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/wallet/fund-debit-wallet`,
                headers: this.headers,
                ...config,
              });
            },
            async subscribe(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/wallet/fund-debit-wallet`,
                headers: this.headers,
                ...config,
              });
            },
          },
          wallet: {
            async fundCreditWallet(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/wallet/fund-credit-wallet`,
                headers: this.headers,
                ...config,
              });
            },
            async fundDebitWallet(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/wallet/fund-debit-wallet`,
                headers: this.headers,
                ...config,
              });
            },
            async get(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/wallet/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
          },
          cards: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/cards/all/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async transactions(account_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/account/transactions/${account_id}`,
                headers: this.headers,
                ...config,
              });
            },
            credit: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/cards`,
                  headers: this.headers,
                  ...config,
                });
              },
              async new(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/creditCard/create`,
                  headers: this.headers,
                  ...config,
                });
              },
              async fund(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/wallet/assign-credit`,
                  headers: this.headers,
                  ...config,
                });
              },
              async increase_limit(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/account/set-account-limit`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
            debit: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/cards`,
                  headers: this.headers,
                  ...config,
                });
              },
              async new(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/debitCard/create`,
                  headers: this.headers,
                  ...config,
                });
              },
              async fund(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/wallet/assign-debit`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
          },
          groups: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/groups`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config,
              });
            },
          },
          staff: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/staff/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config,
              });
            },
          },
          admins: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/staff/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config,
              });
            },
          },
          expenseGroups: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admins`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config,
              });
            },
          },
          repaymentCards: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/card/repayment/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/card/repayment/setup`,
                headers: this.headers,
                ...config,
              });
            },
          },
          activities: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/activities/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async recent(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/activities/recent/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
          },
          permissions: {
            async roles(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/permissions/roles`,
                headers: this.headers,
                ...config,
              });
            },
            async users(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/permissions/users/${this.user.company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async update(config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/companies/permissions/users/update`,
                headers: this.headers,
                ...config,
              });
            },
          },
          expenseCategories: {
            async all(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/category/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/category`,
                headers: this.headers,
                ...config,
              });
            },
          },
          expenseSubCategories: {
            async all(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/category/sub-category/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/category/sub-category`,
                headers: this.headers,
                ...config,
              });
            },
          },
          expensePolicies: {
            async all(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/policy/company/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy`,
                headers: this.headers,
                ...config,
              });
            },
            async update(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy/update`,
                headers: this.headers,
                ...config,
              });
            },
            async attach(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy/attach-to-role`,
                headers: this.headers,
                ...config,
              });
            },
            async delete(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy/delete`,
                headers: this.headers,
                ...config,
              });
            },
          },
          beneficiaries: {
            async all(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/beneficiaries/all/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/beneficiaries/create`,
                headers: this.headers,
                ...config,
              });
            },
          },
          transfers: {
            async all(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/transfers/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/transfer/request`,
                headers: this.headers,
                ...config,
              });
            },
            async approve(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/transfer/approve`,
                headers: this.headers,
                ...config,
              });
            },
          },
          statements: {
            async all(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/statement/company/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async download(statement_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/statement/download/${statement_id}`,
                headers: this.headers,
                ...config,
              });
            },
          },
        },
        personal: {
          cards: {
            async transactions(account_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/card/transactions/${account_id}`,
                headers: this.headers,
                ...config,
              });
            },
            credit: {},
            debit: {},
            corporate: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/cards/user/${this.user.id}`,
                  headers: this.headers,
                  ...config,
                });
              },
              async requestPin(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/card/pin/request`,
                  headers: this.headers,
                  ...config,
                });
              },
              async activate(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/creditCard/activate`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
          },
          bills: {
            flutterwave: {
              async categories(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/flutterwave/bill-categories`,
                  headers: this.headers,
                  ...config,
                });
              },
              async getService(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/flutterwave/bill-payment`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
            capricon: {
              async categories(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/baxi/biller-services`,
                  headers: this.headers,
                  ...config,
                });
              },
              async dataPlans(network, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/baxi/provider-bundles/${network}`,
                  headers: this.headers,
                  ...config,
                });
              },
              async verifyAccount(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/verify-details`,
                  headers: this.headers,
                  ...config,
                });
              },
              async buyAirtime(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/verify-details`,
                  headers: this.headers,
                  ...config,
                });
              },
              async buyElectricity(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/verify-details`,
                  headers: this.headers,
                  ...config,
                });
              },
            },
          },
          expenseCategories: {
            async all(company_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/category/${company_id}`,
                headers: this.headers,
                ...config,
              });
            },
          },
          expenses: {
            async request(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/request`,
                headers: this.headers,
                ...config,
              });
            },
            async report(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/account/expense/update`,
                headers: this.headers,
                ...config,
              });
            },
          },
          expenseRequests: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/request/pending`,
                headers: this.headers,
                ...config,
              });
            },
            async approve(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/request/approve`,
                headers: this.headers,
                ...config,
              });
            },
            async decline(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/request/approve`,
                headers: this.headers,
                ...config,
              });
            },
          },
          beneficiaries: {
            async all(user_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/beneficiaries/all/${user_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/personal/beneficiaries/create`,
                headers: this.headers,
                ...config,
              });
            },
          },
          transfers: {
            async all(user_id, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/transfers/${user_id}`,
                headers: this.headers,
                ...config,
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/personal/transfer`,
                headers: this.headers,
                ...config,
              });
            },
          },
        },
      },
    };
  },
  methods: {
    async sendRequest(path = "", ...params) {
      var fullPath = path.split(".");
      var request;
      try {
        request = fullPath.reduce(
          (last, current) => last[current],
          this.apiRequests
        );
      } catch (e) {
        console.warn(e);
      }
      if (!request || typeof request.constructor !== "function") {
        throw new Error(
          `Invaliid Request ${path}; Request Not Registered In /src/mixins/apis.js`
        );
      }

      return await request.apply(this, [...params]);
    },
  },
});
