export default {
	namespaced: true,
	state: {
		sidebar: {
			open: false
		},
		theme: 'light'
	},
	mutations: {
		closeSidebar(state) {
			state.sidebar.open = false;
		},
		openSidebar(state) {
			state.sidebar.open = true;
		},
		toggleSidebar(state) {
			state.sidebar.open = !state.sidebar.open;
		}
	}
}