<template>
  <div class="-mx-10 -mt-8 h-screen">
    <div class="border-t border-blue-200 h-full mt-2">
      <personal />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    personal: require("./Personal").default,
  },
  data() {
    return {};
  },
  computed: {},
  async beforeMount() {},
  mounted() {},
  methods: {},
};
</script>
