import Vue from 'vue';

Vue.mixin({
  computed: {
    resources() {
      return this.$store.state.resources[this.accountType];
    }
  },
  methods: {
    loadResource(path, forced = true) {
      const resource = path.split('.').reduce((last, next) => last[next], this.resources);
      if (!resource) {
        throw new Error(`Cannot Load Unknown Resource Path: ${path}`);
      }

      var resourceName = path.split().pop();

      this.$store.dispatch('resources/fetch', { mode: this.accountType, resourceName, forced });

    }
  }
});