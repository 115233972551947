<template>
	<div class="select" :class="{open: opened, focus: focused}" @click="click">
		<div class="select-display" v-html="currentTitle"></div>
		<input type="text" class="select-input" ref="input" @focus="focus" @input="input" @blur="blur" @keydown="keydown">
		<ul class="select-options left bottom" ref="menu">
			<template v-if="isList">
				<template v-for="(o, n) in options">
					<li
						class="select-options-item"
						:class="{selected: option == o}"
						v-html="o.title || o.value || o"
						@click="select(o)"
						ref="option"
						:key="n"
					></li>
				</template>
			</template>
			<template v-else-if="isGrid">
				<div class="select-options-grid grid gap-2" :class="`grid-cols-${gridColumns}`">
					<template v-for="(o, n) in options">
						<li
							class="select-options-grid-item"
							:class="{selected: option == o}"
							v-html="o.title || o.value || o"
							@click="select(o)"
							ref="option"
							:key="n"
						></li>
					</template>
				</div>
			</template>
		</ul>
	</div>
</template>

<script>
	import $ from 'jquery';

	export default {
		model: {
			prop: 'value',
			event: 'change'
		},
		props: {
			displayType: {
				type: String,
				default: 'list'
			},
			gridColumns: {
				type: Number,
				default: 4
			},
			multiple: {
				type: Boolean,
				default: false
			},
			options: {
				type: Array,
				default: () => []
			},
			value: {
				type: [String, Number],
				default: ''
			},
		},
		data() {
			return {
				closeTimeout: null,
				focused: false,
				opened: false,
				option: null,
			}
		},
		computed: {
			currentValue() {
				return this.option ? (this.option.value != undefined && this.option.value != null ? this.option.value : this.option) : "&nbsp;";
			},
			currentTitle() {
				return this.option ? (this.option.title != undefined && this.option.title != null ? this.option.title : this.option) : "&nbsp;";
			},
			index() {
				return this.options.findIndex( option => option === this.option );
			},
			isList() {
				return this.displayType == 'list';
			},
			isGrid() {
				return this.displayType == 'grid';
			},
			nextOption() {
				if (this.index <= -1) {
					return this.options[0];
				}
				return this.index < this.options.length - 1 ? this.options[this.index + 1] : this.options[0];
			},
			prevOption() {
				if (this.index <= -1) {
					return this.options[this.options.length - 1];
				}
				return this.index >= 1 ? this.options[this.index - 1] : this.options[this.options.length - 1];
			}
		},
		mounted() {
			this.option = this.options.find( option => option.value == this.value || option == this.value );
			window.addEventListener('click', event => {
				if (!event.target.matches('.select, .select *')) {
					this.opened = false;
				}
			});
		},
		watch: {
			value() {
				this.option = this.options.find( option => option.value == this.value || option == this.value );
			},
			option() {
				if (this.option) {
					this.$emit('change', this.currentValue);
				}
			},
			opened(value) {
				const menu = this.$refs.menu;
				const dimension = menu.getBoundingClientRect();

				setTimeout(() => {
					menu.style.height = 'auto';

					const minX = dimension.x;
					const minY = dimension.y;
					const maxX = minX + menu.offsetWidth;
					const maxY = minY + menu.offsetHeight;
					const windowHeight = window.innerHeight;
					const windowWidth = window.innerWidth;
					if (value === true) {

						if (maxX > windowWidth) {
							// menu.classList.remove('right');
							// menu.classList.add('left');
						}else {
							// menu.classList.remove('left');
							// menu.classList.add('right');
						}

						if (maxY > windowHeight) {

							menu.classList.remove('bottom');
							menu.classList.add('top');

							setTimeout(() => {
								const dimension = menu.getBoundingClientRect();
								const minY = dimension.y;
								const maxY = dimension.height;
								const height = maxY + minY - 70;
								if (minY <= 0) {
									menu.style.height = `${height}px`;
								}

							}, 1);

						}else {
							menu.classList.remove('top');
							menu.classList.add('bottom');
						}

						setTimeout(() => {
							menu.classList.add('active');
							this.positionOption();
						}, 1);

					}else {
						menu.classList.remove('active');
						setTimeout(() => {
							menu.classList.remove('bottom');
							// menu.classList.remove('left');
							// menu.classList.remove('right');
							menu.classList.remove('top');
							menu.style.height = 'auto';
						}, 300);
					}
				}, 1);

			}
		},
		methods: {
			click(event) {
				clearTimeout(this.closeTimeout);

				if (!event.target.matches('.select-options, .select-options *')) {
					this.opened = !this.opened;
				}

				setTimeout(() => {
					this.$refs.input.focus();
				}, 1);
			},
			blur() {
				this.focused = false;
				this.$emit('blur');
				this.closeTimeout = setTimeout(() => {
					this.opened = false;
				}, 300);
			},
			focus() {
				this.focused = true;
				this.$emit('focus');
			},
			input() {
				this.$emit('input');
			},
			keydown(event) {
				switch (event.code) {
					case "ArrowDown":
						event.preventDefault();
						this.option = this.nextOption;
						this.opened = true;
						this.positionOption();
						break;
					case "ArrowUp":
						event.preventDefault();
						this.option = this.prevOption;
						this.opened = true;
						this.positionOption();
						break;
					case "Escape":
						event.preventDefault();
						this.opened = false;
						break;
					case "Space":
					case "Enter":
						this.opened = false;
						event.preventDefault();
						break;
					default:
						if (event.code.match(/^(Key|Digit)/)) {
							const key = event.key;
							const regexp = new RegExp(`^${key}`, 'i');
							const option = this.options.find( option => {
								if (option.title ? option.title.match(regexp) : option.match(regexp)) {
									return true;
								}
							});
							if (option) {
								this.option = option;
								this.positionOption();
							}
						}
						break;
				}
			},
			positionOption() {
				const activeOption = this.$refs.option[this.index];
				const menu = this.$refs.menu;

				if (!activeOption) {
					return false;
				}

				const belowTop = activeOption.offsetTop >= 0 && activeOption.offsetTop > menu.scrollTop;
				const aboveBottom = (activeOption.offsetTop + activeOption.offsetHeight) <= (menu.offsetHeight + menu.scrollTop);
				const inDisplay = belowTop && aboveBottom;
				
				if (!inDisplay) {
					$(menu).animate({
						scrollTop: activeOption.offsetTop
					}, 150);
				}
			},
			select(option) {
				clearTimeout(this.closeTimeout);
				this.option = option;
				this.$emit('change', this.currentValue);
				this.opened = false;
				this.$refs.input.focus();
			}
		}
	}
</script>