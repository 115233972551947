<template>
    <doughnut
        :id="id"
        :data="data"
        :colors="colors"
        :resize="true"
    />
</template>

<script>
    import Morris from 'vue-morris';

    var { DonutChart } = Morris;

    export default {
        props: {
            id: {
                type: String
            },
            data: {
                type: Array,
            },
            colors: {
                type: Array,
            },
        },
        components: {
            doughnut: DonutChart
        },
        mounted() {
            console.log(Morris)
        }
    }
</script>