<template>
  <div ref="element"></div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
// import Vue from "vue";

export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      axios
        .get(this.src)
        .then((response) => {
          $(this.$el).append(response.data);
          const svg = $(this.$el).find("svg");
          // .removeAttr('height')
          // .removeAttr('width')
          if (this.height) {
            svg.attr("height", this.height);
            //.attr('width', this.width);;
          }
        })
        .catch();
      // console.log(cache.get(this.src));
    },
  },
};
</script>
