import Vue from 'vue';
import VueRouter from 'vue-router';
import middlewares from './middlewares';
import $ from 'jquery';
import pages from '../config/pages';

function getRoute(routeName) {
  return pages[routeName];
}

function getRoutes(namespace) {
  const routes = [];

  for ( let pageName in pages ) {
    let page = pages[pageName];
    if (page.route.parent && page.route.parent === namespace) {
      routes.push(page.route);
    }
  }

  return routes;
}

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    ...getRoutes('root'),
    {
      path: '/',
      component: require('@/views/auth/Layout.vue').default,
      children: [
        ...getRoutes('auth'),
      ]
    },

    {
      path: '/',
      component: require('@/views/app/Layout.vue').default,
      children: [
        ...getRoutes('app'),
      ]
    },

    ...getRoutes('errors'),

  ]
})

export default router;


router.beforeEach( async (to, from, next) => {
  /*
  --------------------------------------------------------------------
  | Wait For Store To Be 100% Ready
  --------------------------------------------------------------------
  */

  // Set Time Limit For 5 Seconds 
  var timer = 5000;

  // Wait For Store To Be Mounted
  while (!router.app.$store || !router.app.$store.state.session.ready) {
      const session = router.app.$store?.state?.session;

      if (session?.loggedIn && to.name.match(/login|signup|update-password|password-reset/)) {
        return next({ name: 'dashboard' });
      }

    // Kill Application After Time Limit
    if (timer === 0) {
      window.location.reload();
      return alert('Store Not Ready');
    }

    // Wait 0.01 Seconds For Store To Get Ready
    await new Promise(resolve => setTimeout( resolve, 10 ) );

    // Decrement Timer
    timer -= 10;

  }
  
  $('.pre-loader').addClass('hide');

  /*
  --------------------------------------------------------------------
  | Route Security
  --------------------------------------------------------------------
  */
  // Check If Potential Route Requires A Middleware
  // if (to.meta.middleware) {
  //   // Get Response From Middleware
  //   const redirect = runMiddlewares(to, from, next,  to.meta.middleware);

  //   // Allow Access If Middleware Returns True
  //   if (redirect !== true) {
  //     return false;
  //   }

  // }

  const route = getRoute(to.name);

  // console.log(89, route);

  const middlewares = [...route.middlewares, 'permissions'];


  const redirect = runMiddlewares(to, from, next, middlewares);

  if (redirect !== true) {
    return false;
  }


  /*
  --------------------------------------------------------------------
  | Navigate To Next Route
  --------------------------------------------------------------------
  */
  next();

  router.app.$emit('global', { event: 'navigate', params: [to, from] });

});

function runMiddlewares(to, from, next, middleware) {

  // Check if provided value is an array of middlewares
  if (middleware && middleware.constructor.toString().match(/array/i)) {
    // Loop through all specified middlewares
    var allowed = middleware.every( mdw => {
      // Run middleware if the middleware exists and return allow access if not
      return middlewares[mdw] ? middlewares[mdw].apply(router.app, [to, from, next]) : true;
    } );

    // Return Access Response From All Middlewares
    return allowed;
  }

  // Return Access Response From Middleware
  return middlewares[middleware] ? middlewares[middleware].apply(router.app, [to, from, next]) : true;
}


router.afterEach(to => {
  document.title = to.meta.title;
  if (router.app.sidebar.open) {
    router.app.closeSidebar();
  }
});

router.onError(error => {
  console.log('A Routing Error Occured')
  console.log([error]);
});