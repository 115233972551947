import Cookies from "vue-cookies";
import axios from 'axios';

export default {
    namespaced: true,
    state: {
        /*
        ------------------------------------------------------------------
        Closed Modals
        ------------------------------------------------------------------
        */
        modals: [],
        /*
        ------------------------------------------------------------------
        Token Keyword For Storage
        ------------------------------------------------------------------
        */
        token_name: 'credpal-corporate-loan-modals',
        /*
        ------------------------------------------------------------------
        Storage Timeout
        ------------------------------------------------------------------
        */
        timeout: '2y',
    },
    mutations: {
        closeModal(state, { modal }) {
            state.modals.push(modal);
        }
    },
    actions: {
        closeModal({ commit, rootState }, { modal }) {
            commit('closeModal', { modal });

            const app = this._vm;

            axios({
                url: `${app.$baseurl}/modals/close/${modal}`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${rootState.session.token}`
                }
            });
            // dispatch('saveModals');
        },
        saveModals({ state, rootState }) {
            switch (rootState.store_type) {
                case 'cookies':
                    console.log(state.modals)
                    Cookies.set(state.token_name, JSON.stringify(state.modals), state.timeout);
                    break;
                default:
                    localStorage.setItem(state.token_name, JSON.stringify(state.modals));
                    break;
            }
        },
        loadModals({ state, rootState, commit }) {
            var data;

            switch (rootState.store_type) {
                case 'cookies':
                    data = Cookies.get(state.token_name);
                    break;
                default:
                    data = localStorage.getitem(state.token_name);
                    break;
            }

            data = JSON.parse(data);

            if (data) {
                data.forEach(modal => {
                    commit('closeModal', { modal });
                });
            }
        }
    }
}