<template>
    <vue-paystack
        :amount="(amount * 100).toFixed(0) * 1"
        :reference="reference"
        :paystackkey="paystackKey"
        :channels="'card'"

        :email="email"
        :firstname="firstName"
        :lastname="firstName"

        :close="close"
        :callback="callback"
    >
        <slot>Make Payment</slot>
    </vue-paystack>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: {
            amount: {
                type: [String, Number],
                default: 0
            },
            callback: {
                type: Function,
                default: () => {}
            },
            close: {
                type: Function,
                default: () => {}
            },
        },
        components: {
            VuePaystack: () => import('vue-paystack')
        },
        computed: {
            ...mapState('session', ['keys']),

            firstName() {
                return this.user?.name.split(' ')[0];
            },
            email() {
                return this.user?.email
            },
            lastName() {
                return this.user?.name.split(' ').pop();
            },
            reference() {
                var string = "";
                var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for( let i=0; i < 32; i++ ) {
                    string += chars.charAt(Math.floor(Math.random() * chars.length));
                }

                return string;
            },
            paystackKey() {
                return this.keys.paystack_public_key;
            }
        }
    }
</script>