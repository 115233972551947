<template>
	<label class="checkbox" :class="{isChecked: 'checked'}">
		<input type="checkbox" class="input" :name="name" :id="id" ref="input" @change="changed" :checked="checked">
		<span for="" class="label">
			<ion-icon :name="icon" />
		</span>
	</label>
</template>

<script>
	export default {
		model: {
			prop: 'checked',
			event: 'change'
		},
		props: ['name', 'id', 'checked'],
		data() {
			return {
				isChecked: false
			}
		},
		computed: {
			icon() {
				return this.isChecked ? 'checkbox-outline' : 'square-outline';
			},
		},
		mounted() {
			this.isChecked = !!this.checked;
		},
		methods: {
			changed(){
				this.isChecked = this.$refs.input.checked;
				this.$emit('change', this.isChecked);
			}
		}

	}
</script>