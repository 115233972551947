export default {
  'errors-404': {
    route: {
      name: 'errors-404',
      path: '*',
      parent: 'errors',
      component: () => import('@/views/errors/404.vue'),
      meta: {
        title: 'Page Not Found'
      }
    },
    middlewares: [],
    permissions: ['*'],
  },
  'errors-404-main': {
    route: {
      name: 'errors-404-main',
      path: '/404',
      parent: 'errors',
      component: () => import('@/views/errors/404.vue'),
      meta: {
        title: 'Page Not Found'
      }
    },
    middlewares: [],
    permissions: ['*'],
  },
};