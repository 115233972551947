<template>
  <div class="flex flex-wrap mb-4">
    <div class="w-full px-10 mt-10 flex justify-between">
      <h2 class="font-bold text-3xl mb-10">Conversions</h2>
      <!-- <div class="">
        <div class="flex justify-between">
          <div class="flex flex-wrap md:flex-no-wrap mt-6">
            <div class="filter-date md:mr-6">
              <form-groupd
                type="date"
                rightIcon="calendar-outline"
                name="date_of_birth"
                className="filter-date"
              >
                From
              </form-groupd>
            </div>

            <div class="filter-date">
              <form-groupd
                type="date"
                rightIcon="calendar-outline"
                name="date_of_birth"
                className="filter-date"
              >
                to
              </form-groupd>
            </div>
          </div>
        </div>
      </div> -->
      <div class="w-3/5">
        <search-form
          v-model="searchQuery"
          placeholder="Search for user's name, status, etc."
          @submit="(event) => $refs.table.loadAjaxData()"
        />
      </div>
    </div>

    <div class="w-full px-10 mb-20">
      <div class="border border-solid border-blue-200">
        <datatable2
          :url="url"
          :ajax="true"
          :ajaxPagination="true"
          :index="true"
          :fillable="true"
          :columns="table.columns"
          :limit="10"
          :query="searchQuery"
          :loading="conversions.loading"
          ref="table"
        />
      </div>
      <!-- <div class="grid grid-cols-1 mt-4">
        <div class="col-span-1 data-table-control mb-3 m-auto">
          <ul class="pagination">
            <li
              class="page-item"
              v-for="(page, i) in pages"
              :class="i + 1 === meta.current_page ? 'active' : ''"
              :key="i"
              @click.prevent="getData(i + 1)"
            >
              <span class="page-link">{{ i + 1 }}</span>
            </li>
            <li class="page-item" @click.prevent="nextPage">
              <span class="page-link">Next</span>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      table: this.$options.resource([], {
        columns: [
          {
            name: "customer_name",
            th: "Customer Name",
            render: (conversions) => {
              if (!conversions.owner) {
                return "---";
              }
              return `${conversions.owner?.name} ${conversions.owner.last_name}`;
            },
          },
          {
            name: "created_date",
            th: "Conversion Date",
            render: (conversions) =>
              moment(conversions.created_at).format("MMM Do, YYYY"),
          },
          {
            name: "commission",
            th: "Commission",
            render: (conversions) => {
              if (!conversions.reward) {
                return "---";
              }
              return this.$options.filters.currency(
                conversions.reward.commission || 0
              );
            },
          },
          {
            name: "confirmed_date",
            th: "Confirmed Date",
            render: (conversions) => {
              if (!conversions.confirmed_date) {
                return "---";
              }
              return moment(conversions.confirmed_date).format(
                "MMM Do, YYYY h:mm:ss a"
              );
            },
          },
          {
            name: "status",
            th: "Conversion Status",
            render: (conversions, status) => {
              switch (status) {
                case "approved":
                  return `<div class="badge badge-green-soft-outline">
											Approved
										</div>`;
                case "declined":
                  return `<div class="badge badge-red-soft-outline">
											Rejected
                    </div>`;
                case "incomplete-signup":
                  return `<div class="badge badge-orange-soft-outline">
											Incomplete Signup
										</div>`;
                case "processing":
                  return `<div class="badge badge-orange-soft-outline">
											Processing
										</div>`;
                case "pending":
                default:
                  return `<div class="badge badge-orange-soft-outline">
											Pending
										</div>`;
              }
            },
          },
          {
            name: "status",
            th: "Payment Status",
            render: (conversions) => {
              const status = conversions.reward?.status ?? "not_converted";
              switch (status) {
                case "earned":
                  return `<div class="badge badge-green-soft-outline">
											Earned
										</div>`;
                case "processing":
                  return `<div class="badge badge-orange-soft-outline">
											Processing
										</div>`;
                case "disbursed":
                  return `<div class="badge badge-blue-soft-outline">
											Disbursed
										</div>`;
                case "rejected":
                  return `<div class="badge badge-red-soft-outline">
											Pending
										</div>`;
                case "not_converted":
                default:
                  return `<div class="badge badge-gray-soft-outline">
											Not Converted
										</div>`;
              }
            },
          },
        ],
        selected: null,
      }),
      conversions: this.$options.resource([], {
        loading: false,
      }),
      config: this.$options.resource([]),
      query: null,
      loading: false,
      links: null,
      meta: null,
      pages: [],
      // url: `${this.$baseurl}/admin/affiliates`,
      url: `${this.$baseurl}/affiliate/customers`,
      searchQuery: "",
    };
  },
  computed: {},
  async beforeMount() {
    this.getConversions();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    async getConversions() {
      this.conversions.loading = true;
      await this.sendRequest("affiliate.conversions.customers", {
        success: (response) => {
          // const { data } = response.data.data;
          // this.conversions.data = data;
          const { data, links, meta } = response.data.data;
          this.links = links;
          this.meta = meta;
          this.pages = new Array(meta.last_page);
          this.conversions.data = data.slice(0, 10);
        },
        error: (error) => {
          this.conversions.error = error;
        },
      });
      this.conversions.loading = false;
    },
    async nextPage() {
      await this.$get({
        url: `${this.links.next}`,
        headers: this.headers,
        success: (response) => {
          const { data, links, meta } = response.data.data;
          this.links = links;
          this.meta = meta;
          this.pages = new Array(meta.last_page);
          this.conversions.data = data.slice(0, 4);
        },
        error: () => {},
      });
    },
    async getData(pageNumber) {
      await this.$get({
        url: `${this.$baseurl}/affiliate/customers?page=${pageNumber}`,
        headers: this.headers,
        success: (response) => {
          const { data, links, meta } = response.data.data;
          this.links = links;
          this.meta = meta;
          this.pages = new Array(meta.last_page);
          this.conversions.data = data.slice(0, 4);
        },
        error: () => {},
      });
    },
  },
};
</script>
