import Vue from 'vue';

Vue.mixin({
    methods: {
        closeModal(modalName) {
            this.$store.dispatch('modals/closeModal', { modal: modalName });
        },
        isModalClosed(modalName) {
            const modals = this.$store.state.modals.modals;

            return !!modals.find(modal => modal === modalName);
        },
        openModal(modalName, modal) {
            if (this.isModalClosed(modalName)) {
                return false;
            }

            modal.open();
        }
    }
});