<template>
	<div class="app-layout">

		<sidebar />

		<div class="app-content">
			<navbar />
			
			<transition name="fade" mode="out-in">
				<router-view class="mbs-32" />
			</transition>
		</div>

	</div>
</template>

<script>
	export default {
		components: {
			navbar: require('@/views/app/components/Navbar.vue').default,
			sidebar: require('@/views/app/components/sidebar/Index.vue').default,
		}
	}
</script>