<template>
	<staff />	
</template>

<script>
	export default {
		components: {
			staff: require('./personal/Index.vue').default,
		}
	}
</script>