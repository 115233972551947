<template>
  <div class="-mx-10 -mt-8 h-screen">
    <div class="border-t border-blue-200 h-full mt-2">
      <conversions />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    conversions: require("./Conversions").default,
  },
};
</script>
