import $ from 'jquery';

const defaultOPtions = {
	displayCount: 4,
	index: 0,
	itemWidth: 65
};

class CardSlider {
	constructor(root, options = {}) {
		this.root = root;
		this.items = this.root.children('.card-slider-item');
		this.length = this.items.length;

		this.mapOptions(options);

		this.listenForResize();
	}

	mapOptions(options) {
		this.config = {
			...defaultOPtions,
			options
		};
	}

	getActiveItems() {
		this.activeItems = this.items.filter((index, element) => {
			if (index >= this.config.index && index < this.config.index + this.config.displayCount) {
				$(element).addClass('active');
				return true;
			}else {
				$(element).removeClass('active');
				return false;
			}
		});
	}

	next() {
		this.config.index = this.config.index < this.length - 1 ? this.config.index + 1 : this.config.index;
		this.render();
		return this;
	}

	prev() {
		this.config.index = this.config.index > 0 ? this.config.index - 1 : this.config.index;
		this.render();
		return this;
	}

	listenForResize() {
		$(window).on('resize', () => {
			this.render();
		});
	}

	render() {

		this.getActiveItems();

		const diff = 30;
		var lastScale;
		var lastElement;
		this.activeItems.each( (index, item) => {

			const scale = lastScale = lastScale !== undefined ? lastScale - (diff / 100 * lastScale) : 1;
			$(item).css({
				transform: `translateY(-50%) scale(${scale})`,
				width: `${this.config.itemWidth}%`,
				zIndex: this.config.displayCount - index
			});

			$(item).find('.card-slider-item-inner').css('opacity', scale);

			$(item).css({
				left() {
					if (!lastElement) {
						return 40;
					}
					var itemWidth = item.getBoundingClientRect().width;
					return (lastElement[0].offsetLeft + lastElement[0].getBoundingClientRect().width) - itemWidth + (10 / 100 * itemWidth)
				}
			});

			if (index === 0) {
				this.root.css('min-height', $(item).outerHeight() + 60);
			}

			lastElement = $(item);

			$(item).trigger('custom.update');

		});

		return this;

	}
}


$.fn.cardSlider = function() {
	return new CardSlider(this).render();
}