import Vue from 'vue';

Vue.mixin({
	activities: {
		admin_created: {
			level: '2',
			observer(activity, creator, [target]) {
				return `An admin account was created by <span class="company-user-tag">${creator.name}</span> for <span class="company-user-tag">${target?.name || 'Deleted User'}</span>`;
			},
			creator(activity, [target]) {
				return `You created an admin account for <span class="company-user-tag">${target?.name || 'Deleted User'}</span>`;
			},
			target(activity, creator) {
				return `An admin account was created by <span class="company-user-tag">${creator.name}</span> for you`
			},
		},
		bank_statement_uploaded: {
			level: '2',
			observer(activity, creator) {
				return `<span class="company-user-tag">${creator.name}</span> uploaded their bank statement`;
			},
			creator() {
				return `You uploaded your bank statement`;
			},
		},
		company_created: {
			level: '3',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> registered a new company called <span class="company-tag">${target.company.name}</span>`;
			},
			creator(activity, [target]) {
				return `You registered a new company called <span class="company-tag">${target.company.name}</span>`;
			},
			target(activity, creator, [target]) {
				return `Your company <span class="company-tag">${target.company.name}</span> was registered`;
			},
		},
		company_profile_updated: {
			level: '3',
			observer(activity, creator) {
				return `<span class="company-tag">${creator.company.name}</span> company profile was updated`;
			},
			creator() {
				return `You updated your company's profile`;
			},
		},
		company_deleted: {
			level: '3',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> deleted <span class="company-tag">${target?.compant?.name || 'Deleted'}</span>`;
			},
			creator(activity, [target]) {
				return `You deleted <span class="company-tag">${target?.compant?.name || 'Deleted'}</span>`;
			},
			target(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> deleted your company <span class="company-tag">${target?.compant?.name || 'Deleted'}</span>`;
			},
		},
		group_created: {
			level: '3',
			observer(activity) {
				return `Group ${activity.data?.group?.name} Created`;
			},
			creator(activity) {
				return `Group ${activity.data?.group?.name} Created`;
			},
			target(activity) {
				return `Group ${activity.data?.group?.name} Created`;
			},
		},
		group_deleted: {
			level: '3',
			observer(activity) {
				return `Group ${activity.data?.group?.name} Deleted`;
			},
			creator(activity) {
				return `Group ${activity.data?.group?.name} Deleted`;
			},
			target(activity) {
				return `Group ${activity.data?.group?.name} Deleted`;
			},
		},
		password_changed: {
			level: '1',
			observer(activity, creator) {
				return `<span class="company-user-tag">${creator.name}</span> updated their password`
			},
			creator() {
				return `You updated your password`
			},
		},
		repayment_paid: {
			all() {
				return `Repayment Paid`;
			}
		},
		repayment_bank_transfer: {
			all() {
				return `Bank Transfer For Repayment`;
			}
		},
		staff_activated: {
			level: '1',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> activated <span class="company-user-tag">${target?.name || 'Deleted User'}</span>' profile`;
			},
			creator(activity, [target]) {
				return `You activated <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s profile`;
			},
			target() {
				return `Your profile was activated`;
			},
		},
		staff_created: {
			level: '1',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> created a staff account for <span class="company-user-tag">${target?.name || 'Deleted User'}</span>`;
			},
			creator(activity, [target]) {
				return `You created a staff account for <span class="company-user-tag">${target?.name || 'Deleted User'}</span>`;
			},
			target() {
				return `Your account was created`;
			},
		},
		staff_deactivated: {
			level: '1',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> deactivated <span class="company-user-tag">${target?.name || 'Deleted User'}</span>' profile`;
			},
			creator(activity, [target]) {
				return `You deactivated <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s profile`;
			},
			target() {
				return `Your profile was activated`;
			},
		},
		staff_deleted: {
			level: '2',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> deleted <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s account`;
			},
			creator(activity, [target]) {
				return `You deleted <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s account`;
			},
			target() {
				return `Your account was deleted`;
			},
		},
		staff_loan_approved: {
			level: '3',
			all(activity) {
				return `${activity.data?.loan.loan_type?.fromSlug()} Loan Approved`;
			},
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> approved <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			creator(activity, [target]) {
				return `You approved <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			target() {
				return `Your loan request was approved`;
			}
		},
		staff_loan_created: {
			level: '3',
			all(activity) {
				return `${activity.data?.loan.loan_type?.fromSlug()} Loan Requested`;
			},
			observer(activity, creator) {
				return `<span class="company-user-tag">${creator.name}</span> requested for a loan`;
			},
			creator() {
				return `You requested for a loan`;
			}
		},
		staff_loan_declined: {
			level: '3',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> declined <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			creator(activity, [target]) {
				return `You declined <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			target() {
				return `Your loan request was declined`;
			}
		},
		staff_loan_disbursed: {
			level: '3',
			all(activity) {
				return `${activity.data?.loan.loan_type?.fromSlug()} Loan Disbursed`;
			},
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> declined <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			creator(activity, [target]) {
				return `You declined <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			target() {
				return `Your loan request was declined`;
			}
		},
		staff_loan_pre_approved: {
			level: '3',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> approved <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			creator(activity, [target]) {
				return `You approved <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			target() {
				return `Your loan request was approved by your HR`;
			}
		},
		staff_loan_pre_declined: {
			level: '3',
			observer(activity, creator, [target]) {
				return `<span class="company-user-tag">${creator.name}</span> declined <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			creator(activity, [target]) {
				return `You declined <span class="company-user-tag">${target?.name || 'Deleted User'}</span>'s loan request`;
			},
			target() {
				return `Your loan request was declined by your HR`;
			}
		},
		staff_profile_updated: {
			level: '1',
			observer(activity, creator) {
				return `<span class="company-user-tag">${creator.name}</span> updated their profile`
			},
			creator() {
				return `You updated your profile`
			}
		}
	},
	methods: {
		getActivityDescription(activity) {
			const user = this.user;
			const users = activity.users;
			const creator = users.find( activity_user => activity_user.pivot.position === 'creator' );
			const targets = users.filter( activity_user => activity_user.pivot.position === 'target' );
			const isInvolved = users.find( activity_user => activity_user.id === user.id );
			const isCreator = creator.id === user.id;
			const activity_type = activity.type;

			const activities = this.$options.activities;
			if (!activities[activity_type]) {
				throw new Error(`Unknown Activity Type: ${activity_type}`);
			}

			const method = activities[activity_type].all ? 'all' : isCreator ? 'creator' : isInvolved ? 'target' : 'observer';

			switch (method) {
				case 'all':
					return activities[activity_type].all.apply(this, [activity, creator, targets]);
				case 'creator':
					return activities[activity_type].creator.apply(this, [activity, targets]);
				case 'target':
					return activities[activity_type].target.apply(this, [activity, creator, targets]);
				case 'observer':
				default:
					return activities[activity_type].observer.apply(this, [activity, creator, targets]);
			}

		},
		getActivityLevel(activity) {
			const activity_type = activity.type;
			const activities = this.$options.activities;
			if (!activities[activity_type]) {
				throw new Error(`Unknown Activity Type: ${activity_type}`);
			}

			return activities[activity_type].level;
		}
	}
});
