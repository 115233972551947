import Cookies from "vue-cookies";

export default {
    namespaced: true,
    state: {
        /*
        ------------------------------------------------------------------
        Affiliate code
        ------------------------------------------------------------------
        */
        affiliateCode: '',
        /*
        ------------------------------------------------------------------
        Storage Timeout
        ------------------------------------------------------------------
        */
        timeout: '2y',
    },
    mutations: {
        setAffiliateCode(state, payload) {
            state.affiliateCode = payload
        }
    },

    actions: {
        setAffiliateCode(state) {
            const data = Cookies.get('ac');
            state.commit('setAffiliateCode', data)
        }
    }
}