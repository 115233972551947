<template>
    <svg width="313" viewBox="0 0 313 204" fill="none" xmlns="http://www.w3.org/2000/svg" style="max-width: 100%; width: 400px">
        <g clip-path="url(#clip0)">
            <rect width="313" height="204" rx="8" :fill="bg"/>
            <circle opacity="0.04" cx="244.5" cy="190.5" r="103.5" fill="white"/>
            <ellipse opacity="0.04" cx="296.5" cy="75" rx="103.5" ry="104" fill="white"/>
            <circle opacity="0.04" cx="42" cy="28" r="99" fill="white"/>

            <template v-if="loading">
                <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform 
                        attributeName="transform" 
                        attributeType="XML" 
                        type="rotate"
                        dur="1s" 
                        from="0 50 50"
                        to="360 50 50" 
                        repeatCount="indefinite" />
                </path>
            </template>
            <template v-else>
                <template v-if="provider === 'visa'">
                    <path d="M60.4816 37.2844C60.4484 39.919 62.8411 41.3889 64.6424 42.2637C66.4941 43.1601 67.1158 43.7354 67.1091 44.5375C67.0949 45.7646 65.6323 46.3068 64.2628 46.3276C61.8739 46.3644 60.4844 45.6862 59.3806 45.1723L58.5198 49.1805C59.6274 49.6887 61.6794 50.1317 63.8063 50.1516C68.7995 50.1516 72.0672 47.6983 72.0843 43.8941C72.1033 39.066 65.3751 38.7986 65.4207 36.641C65.4368 35.9864 66.0642 35.2892 67.4385 35.1116C68.119 35.0219 69.9972 34.9529 72.1261 35.9288L72.9622 32.0509C71.8167 31.6353 70.3456 31.2385 68.5129 31.2385C63.8129 31.2385 60.5063 33.7249 60.4797 37.2853L60.4816 37.2844ZM80.9944 31.572C80.0824 31.572 79.3136 32.101 78.971 32.9134L71.8375 49.8653H76.8269L77.8197 47.1343H83.9177L84.4938 49.8653H88.8919L85.0537 31.571H80.9935L80.9944 31.572ZM81.692 36.5145L83.1318 43.384H79.1883L81.692 36.5145V36.5145ZM54.4311 31.572L50.4971 49.8663H55.2521L59.1842 31.572H54.4311V31.572ZM47.3964 31.572L42.4469 44.0236L40.4452 33.4358C40.2098 32.254 39.2826 31.571 38.2518 31.571H30.1608L30.0479 32.1019C31.7088 32.4609 33.5965 33.039 34.7392 33.6578C35.4387 34.0357 35.638 34.3663 35.8687 35.2656L39.6603 49.8644H44.6858L52.3896 31.5701H47.3964V31.572Z" fill="white"/>
                </template>
                <template v-else-if="provider === 'mastercard'">
                    <circle cx="45.5" cy="40.5" r="15.5" fill="#EE0006"/>
                    <circle cx="66.1665" cy="40.5" r="15.5" fill="#F99F00"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M55.8337 52.0532C59.0047 49.2151 61.0003 45.0906 61.0003 40.5C61.0003 35.9094 59.0047 31.7849 55.8337 28.9468C52.6626 31.7849 50.667 35.9094 50.667 40.5C50.667 45.0906 52.6626 49.2151 55.8337 52.0532Z" fill="#FF5E00"/>
                </template>
                <!-- <text x="30" y="156" fill="#fff" font-size="16" font-family="SF UI Text" letter-spacing="1.5">**** **** **** {{ last4 }}</text> -->
                <text x="30" y="156" fill="#fff" font-size="16" font-family="SF UI Text" letter-spacing="1.5">{{ name }}</text>
                <!-- <text x="30" y="170" fill="#799DF7" font-size="7.5" font-family="SF UI Text" font-weight="medium" letter-spacing=".9">VALID THRU {{ expMonth }}/{{ expYear }}</text> -->
                <text x="30" y="170" fill="#799DF7" font-size="9" font-family="SF UI Text" font-weight="medium" letter-spacing=".9">{{ type.toUpperCase() }}</text>
            </template>

        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="313" height="204" rx="8" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    import $ from 'jquery';

    export default {
        props: {
            expMonth: {
                type: String
            },
            expYear: {
                type: String
            },
            last4: {
                type: String,
                default: '0016'
            },
            type: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            cardStyle: {
                type: String,
                default: 'blue'
            },
            provider: {
                type: String,
                default: 'visa'
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                cardBG: require('@/assets/card-design-plain.png')
            }
        },
        computed: {
            bg() {
                switch (this.cardStyle) {
                    case 'blue-dark':
                        return '#1F1F57';
                    case 'blue':
                    default:
                        return '#3445DE';
                }
            }
        },
        mounted() {
            $(this.$el).find('[data-fit]').each( (index, element) => {
                $(element).resizeText($(element).data('fit'));
            } );
        },
        methods: {
            imgLoaded() {
                $(this.$el).find('[data-fit]').each( (index, element) => {
                    $(element).resizeText($(element).data('fit'));
                } );
            }
        }
    }
</script>