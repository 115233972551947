import Vue from 'vue';

Vue.mixin({
  methods: {
    getMostCurrentTimestamp(_array){
      const date = _array.reduce((a, b) => {
         return new Date(a.created_at) > new Date(b.created_at) ? a : b;
       });
     
       return new Date(date.created_at);
     },
    addHours(_dateObj, _numberOfHours){
      var result = new Date(_dateObj);
      result.setHours(result.getHours() + _numberOfHours);
      return result;
    },
    
    addDays(_dateObj, _numberOfDays) {
      var result = new Date(_dateObj);
      result.setDate(result.getDate() + _numberOfDays);
      return result;
    },
    
    
    addMonth(_dateObj, _numberOfMonth) {
      var result = new Date(_dateObj)
      result.setMonth(result.getMonth() + _numberOfMonth);
      return result;
    },
    
    getHour(_timestamp){
      var d = new Date(_timestamp);
      return d.getHours();
    },
    
    getPreviousHours(collection, _numberOfHours,   currentTimestamp = null){
      currentTimestamp = currentTimestamp || this.getMostCurrentTimestamp(collection);
      let $index = 0;
      let $hourArray = [];
      while( $index <= _numberOfHours){
        let hr = this.addHours(currentTimestamp, -$index)
        $hourArray.push(hr);
        $index++
      }
      return $hourArray.sort();
    },
    
    sortTimestamp(timeArray){
      return timeArray.sort();
    },
    
    
    getPreviousDays(collection, _numberOfDays,  currentTimestamp = null){
      currentTimestamp = currentTimestamp || this.getMostCurrentTimestamp(collection);
      let $index = 0;
      let $hourArray = [];
      while( $index <= _numberOfDays){
        let hr = this.addDays(currentTimestamp, -$index)
        $hourArray.push(hr);
        $index++
      }
      return $hourArray.reverse();
    },
    
    getPreviousByTwoDays(collection, _numberOfDays,  currentTimestamp = null){
      currentTimestamp = currentTimestamp || this.getMostCurrentTimestamp(collection);
      let $index = 0;
      let $hourArray = [];
      while( $index <= _numberOfDays){
        let hr = this.addDays(currentTimestamp, -$index)
        $hourArray.push(hr);
        $index += 2;
      }
      return $hourArray.reverse();
    },
    
    getPreviousMonths(collection, _numberOfMonths, currentTimestamp = null){
      currentTimestamp = currentTimestamp || this.getMostCurrentTimestamp(collection);
      let $index = 0;
      let $hourArray = [];
      while( $index <= _numberOfMonths){
        let hr = this.addMonth(currentTimestamp, -$index)
        $hourArray.push(hr);
        $index++
      }
      return $hourArray.reverse();
    },
    
    yearGroup(months, collection){
      months = months || this.getPreviousMonths(collection, 11);
      let total = [];
      months.forEach(el => {
        let mm = new Date(el).getMonth();
        let my = new Date(el).getYear();
        let result = collection.filter(el => {
          let cm = new Date(el.created_at).getMonth();
          let cy = new Date(el.created_at).getYear();
          return mm == cm && my == cy;
        });
        total.push(result)
      });
    
      return this.getTrueContent(total);
    }, 
    
    weekGroup(days, collection){
      days = days ||  this.getPreviousDays(collection, 6);
      let total = [];
      days.forEach(el => {
        let d = new Date(el);
        let dd = d.getDate();
        let dm = d.getMonth();
        let dy = d.getYear();
        let result = collection.filter(el => {
          let c = new Date(el.created_at);
          let cd= c.getDate();
          let cm = c.getMonth();
          let cy = c.getYear();
          return dd == cd && dm == cm && dy == cy;
        });
        total.push(result)
      });
      return this.getTrueContent(total);
    },
    
    monthGroup(days, collection){
      days = days = this.getPreviousDays(collection,12);
      let total = [];
      days.forEach(el => {
        let d = new Date(el);
        let dd = d.getDate();
        let dm = d.getMonth();
        let dy = d.getYear();
        let result = collection.filter(el => {
          let c = new Date(el.created_at);
          let cd= c.getDate();
          let cm = c.getMonth();
          let cy = c.getYear();
          return dd == cd && dm == cm && dy == cy;
        });
        total.push(result)
      });
      return this.getTrueContent(total);
    },

    dayGroup(hours, collection){
      hours = hours || this.getPreviousHours(collection, 12);
      let total = [];
      hours.forEach(el => {
        let dh = new Date(el).getHours();
        let dd = new Date(el).getDate();
        let dm = new Date(el).getMonth();
        let dy = new Date(el).getYear();
        let result = collection.filter(el => {
          let ch= new Date(el.created_at).getHours();
          let cd= new Date(el.created_at).getDate();
          let cm = new Date(el.created_at).getMonth();
          let cy = new Date(el.created_at).getYear();
          return dh == ch && dd == cd && dm == cm && dy == cy;
        });
        total.push(result)
      });
      return this.getTrueContent(total);
    },
    getTrueContent(collection){
      let result = [];
      collection = collection.filter(el => el.length > 0);
      collection.forEach(el => {
        el.forEach(a => result.push(a));
      });
      return result;
    }
    
  }
});

