<template>
  <div>

    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold">All users</h2>
        <router-link :to="{ name: 'users-new'}" class="text-sm text-gray-700 md:ml-3">+ Add new user</router-link>
      </div>
      <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
            placeholder="Search for user's name, status, etc."
            v-model="searchQuery">
          <ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
        </div>
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex">
        <div class="duration-300" :class="{'w-full': !isOpen, 'w-1/2': isOpen}">
          <datatable
            :data="users.data"
            :columns="columns"
            :onClick="click"
            :query="searchQuery"
            :className="tableRowClassName"
            :loading="users.loading"
            ref="table"
          />
        </div>
        <div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
          <div class="h-full overflow-y-auto">
            
            <div class="animated animation-delay-300 fadeIn">
              <div class="mb-5">
                <div class="flex flex-row items-center justify-between mb-1">
                  <div class="flex flex-row items-center">
                    <h2 class="text-lg font-bold mr-2">{{ selectedUser.name | sentenceCase }}</h2>
                  </div>
                  <div class="dropdown ml-auto">
                    <button type="button" class="flex flex-col focus:outline-none" dropdown>
                      <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                      <div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
                      <div class="h-1 w-1 bg-gray-400 rounded-full"></div>
                    </button>
                    <ul class="dropdown-menu">
                      <a href="#!" class="dropdown-menu-item text-red-500" @click.prevent="confirmDelete">Delete</a>
                    </ul>
                  </div>
                </div>
                <div class="font-hairline text-xs text-gray-500">{{ position | sentenceCase }}</div>
                <router-link
                  :to="{ name: 'staff-view', params: { staffId: selectedUser.id } }"
                  target="_blank"
                  class="inline-flex items-center text-sm text-blue-500"
                >
                  <span>View Profile</span>
                </router-link>
              </div>

              <transition name="fade" mode="out-in">
                <div class="h-100px" v-if="userApprovalLoading">
                  <sm-loader />
                </div>

                <div v-else>
                    
                  <!-- Profile Not Completed -->
                  <div class="mb-10" v-if="!userProfileCompleted">

                    <div class="alert alert-blue-soft mb-5">
                      <span class="alert-icon">!</span>
                      <span>{{ userFirstName }} hasn't completed their profile.</span>
                    </div>

                  </div>
                  <!-- Profile Not Completed -->

                  <!-- Profile Completed -->
                  <template v-else>
                    <div class="text-xs">
                      <template v-if="userApprovalCompleted">

                        <div class="alert alert-green-soft mb-5">
                          <span class="alert-icon">!</span>
                          <div class="text-xs font-normal">
                            <template v-if="userApproval && userApproval.action == 'activate'">
                              Profile activated successfully.
                            </template>
                            <template v-else>
                              Profile deactivated successfully.
                            </template>
                          </div>
                        </div>
                        
                      </template>

                      <template v-else-if="userApprovalPending">
                          
                        <div class="alert alert-blue-soft mb-5">
                          <span class="alert-icon">!</span>
                          <span>{{ userFirstName }}'s profile needs activation.</span>
                        </div>

                      </template>

                    </div>

                    <!-- <div class="mb-10">
                      <div
                        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 mb-2"
                        v-if="!userApprovalLoading"
                      >
                        <div class="col-span-1">
                          <div class="flex justify-start items-center">
                            <button
                              class="button bg-blue-500 hover:bg-blue-500 text-white"
                              @click.prevent="activateProfile"
                              v-if="!userProfileActivated"
                            >
                              Activate Profile
                            </button>
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="flex justify-start md:justify-end lg:justify-start xl:justify-end items-center">
                            <button
                              class="button border border-red-500 hover:bg-red-500 text-red-500 hover:text-white"
                              @click.prevent="deactivateProfile"
                              v-if="!userProfileDeactivated"
                            >
                              Deactivate Profile
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="text-red-500 text-xs text-right" v-if="userApprovalError">
                        {{ userApprovalError }}
                      </div>
                    </div> -->

                  </template>
                  <!-- Profile Completed -->

                </div>

              </transition>

              <div class="grid grid-cols-1 xl:grid-cols-2 border-blue-200 border mb-8">

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Salary</div>
                      <div class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black">
                        ₦
                      </div>
                    </div>
                    <div class="text-xs font-medium">₦ {{ selectedUser.profile ? selectedUser.profile.salary : 0 | currency }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-t xl:border-t-0 xl:border-l border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Position</div>
                      <div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
                        <ion-icon name="briefcase-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">{{ selectedUser.profile ? selectedUser.profile.position : '---' }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Phone Number</div>
                      <div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
                        <ion-icon name="call-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">{{ selectedUser.profile ? selectedUser.profile.phone_no : '---' }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Email</div>
                      <div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
                        <ion-icon name="mail-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">{{ selectedUser.email }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Employment Type</div>
                      <div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
                        <ion-icon name="briefcase-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">{{ selectedUser.profile ? selectedUser.profile.emp_type : '---' }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Loan Facility</div>
                      <div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
                        <ion-icon name="calculator-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">{{ selectedUser.profile ? selectedUser.profile.current_loan_facility : '---' }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Total Loan Amount</div>
                      <div class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black">
                        ₦
                      </div>
                    </div>
                    <div class="text-xs font-medium">₦ {{ totalLoanAmount | currency }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
                    <!-- <span class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex justify-between items-center mb-4">
                      <div class="text-xs">Current Loan</div>
                      <div class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black">
                        ₦
                      </div>
                    </div>
                    <div class="text-xs font-medium">₦ 0.00</div>
                  </div>
                </div>

              </div>

              <!-- <h2 class="text-lg font-bold mb-5">Recent Transactions</h2>

              <div class="border border-solid border-blue-200">
                <datatable :data="transactions" :columns="transactionColumns" />
              </div> -->

            </div>
            
          </div>
        </div>
      </div>
    </div>

    <modal className="w-full md:w-2/5 xl:w-3/10" ref="approvalConfirmationModal">

      <h1 class="text-xl font-bold mb-10">Approve This User For Credit</h1>

      <form @submit.prevent="approve">

        <template v-if="getFormError(approvalForm)">
          <div class="alert alert-red-soft mb-10">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(approvalForm) }}</span>
          </div>
        </template>

        <form-group
          type="money"
          left-icon="card-outline"
          name="credit_limit"
          :form="approvalForm"
          v-model="approvalForm.data.credit_limit.value"
        >
          Credit Limit
        </form-group>

        <form-group
          type="money"
          left-icon="card-outline"
          name="loan_limit"
          :form="approvalForm"
          v-model="approvalForm.data.loan_limit.value"
        >
          Standard Loan Limit
        </form-group>

        <form-group
          left-icon="bar-chart-outline"
          name="interest_rate"
          :form="approvalForm"
          v-model="approvalForm.data.interest_rate.value"
        >
          Standard Loan Interest Rate
        </form-group>

        <form-group
          left-icon="calendar-outline"
          name="billing_date"
          :form="approvalForm"
          v-model="approvalForm.data.billing_date.value"
        >
          Billing Date Difference
        </form-group>

        <form-group
          left-icon="calendar-outline"
          name="payment_date"
          :form="approvalForm"
          v-model="approvalForm.data.payment_date.value"
        >
          Payment Date Difference
        </form-group>

        <div class="text-center">
          <button type="submit" class="btn btn-md btn-blue" :disabled="approvalForm.loading">
            <span v-if="approvalForm.loading">Approving</span>
            <span v-else>Approve Credit</span>
          </button>
        </div>

      </form>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="confirmDeleteModal">

      <div class="mb-10">
        <div class="text-xl font-bold mb-10">
          Delete This User?
        </div>

        <div class="font-light text-gray-500 text-sm">
          <p class="mb-2">This user will be permanently removed from the platform.</p>
        </div>
      </div>

      <div class="flex items-center justify-center">

        <button
          type="button"
          class="button bg-blue-500 hover:bg-blue-600 text-white"
          @click.prevent="$refs.confirmDeleteModal.close"
          :disabled="deleting"
        >
          Cancel
        </button>
        

        <button
          type="button"
          class="button bg-red-500 hover:bg-red-600 text-white ml-3"
          @click.prevent="deleteuser"
          :disabled="deleting"
        >
          <span v-if="deleting">Deleting...</span>
          <span v-else>Proceed</span>
        </button>
      </div>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deleteModal">

      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Delete!
      </div>
      <div class="text-xs mb-10">
        selectedUser Deleted Successfully.
      </div>

      <button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.deleteModal.close">
        Okay
      </button>
      
    </modal>

  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        columns: [
          {
            name: 'name',
            th: 'Name',
          },
          {
            name: 'company',
            th: 'Company',
            render: (user, company) => company?.name || '----'
          },
          {
            name: 'email',
            th: 'Email Address',
          },
          {
            name: 'roles',
            th: 'Roles',
            render: (user, roles) => roles?.map(role => role.name).join(', ')
          },
          {
            name: 'status',
            th: 'Profile Approval',
            render: user => {
              if (!user.roles?.find(role => role.slug.match(/company_staff/))) {
                return `<div class="badge badge-gray-soft-outline">
                  Not A Staff
                </div>`;
              }

              if (!user.profile) {
                return `<div class="badge badge-gray-soft-outline">
                  Not Updated
                </div>`;
              }

              switch (user.profile.status) {
                case 'activated':
                  return `<div class="badge badge-green-soft-outline">
                    Activated
                  </div>`;
                case 'deactivated':
                  return `<div class="badge badge-red-soft-outline">
                    Deactivated
                  </div>`;
                case 'pending':
                default:
                  return `<div class="badge badge-orange-soft-outline">
                    Pending
                  </div>`;
              }
            }
          },
          {
            name: 'loans',
            th: 'Total Loans',
            render: user => user.loan?.length
          }
        ],
        checkmark: require('@/assets/checkmark-base.svg'),
        deleting: false,
        selectedUser: null,
        users: this.$options.resource([]),
        approvalForm: this.$options.basicForm(['credit_limit', 'loan_limit', 'interest_rate', 'billing_date', 'payment_date']),
        loading: null,
        profileApprovals: [],
        searchQuery: '',
        transactionColumns: [
          {
            name: 'created_at',
            th: 'Time',
            render: (transaction, created_at) => {
              return this.$options.filters.dateFormat(created_at, 'M dd')+' at '+this.$options.filters.dateFormat(created_at, 'h:ia')
            }
          },
          {
            name: 'status',
            th: 'Status',
            render: (transaction, status) => {
              switch (status) {
                case 'approved':
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                    Confirmed
                  </div>`;
                case 'declined':
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                    Pending
                  </div>`;
                case 'paid':
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                    Confirmed
                  </div>`;
                case 'pending':
                default:
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                    Pending
                  </div>`;
              }
            }
          },
          {
            align: 'right',
            name: 'loan_amount',
            th: 'Amount',
            render: (transaction, amount) => `₦ ${this.$options.filters.currency(amount)}`
          },
        ]
      }
    },
    computed: {
      ...mapState(['banks']),
      
      userFirstName() {
        return this.selectedUser?.name.split(' ')[0];
      },
      userProfileActivated() {
        return (!!this.userApprovalCompleted 
          && this.userApproval.action === 'activate')
          || this.selectedUser?.profile?.status === 'activated';
      },
      userProfileCompleted() {
        return this.selectedUser?.profile;
      },
      userProfileDeactivated() {
        return (!!this.userApprovalCompleted 
          && this.userApproval.action === 'deactivate')
          || this.selectedUser?.profile?.status === 'deactivated';
      },
      userApprovalPending() {
        return this.selectedUser?.profile?.status === 'pending';
      },
      userApproval() {
        return this.profileApprovals.find( approval => approval.id === this.selectedUser.id );
      },
      userApprovalCompleted() {
        return this.userApproval?.response?.data?.status;
      },
      userApprovalError() {
        const error = this.userApproval?.error;
        if (!error) {
          return null;
        }

        if (error?.toString().match(/Error: Network Error/i)) {
          return 'Please check your internet connection';
        }

        if (error?.response?.data?.message) {
          return error?.response?.data?.message;
        }

        return 'Please try again later.';

      },
      userApprovalLoading() {
        return this.userApproval?.loading === true;
      },
      isOpen() {
        return !!this.selectedUser
      },
      position() {
        return this.selectedUser?.profile?.position;
      },
      totalLoanAmount() {
        return this.transactions && this.transactions.reduce( (last, transaction) => last + Number(transaction.loan_amount), 0 );
      },
      transactions() {
        return this.selectedUser?.loan;
      },
    },
    beforeMount() {
      this.getUsers();
    },
    methods: {
      activateProfile() {
        this.$refs.approvalConfirmationModal.open();
      },
      async approve() {
        if (!this.validateForm(this.approvalForm)) {
          return false;
        }

        const selectedUser = this.selectedUser;

        this.profileApprovals = this.profileApprovals.filter(approval => approval.id != selectedUser.id);
        this.profileApprovals.push({
          id: selectedUser.id,
          action: 'activate',
          loading: true,
          response: null,
          error: null,
        });

        this.approvalForm.loading = true;

        await this.$post({
          url: `${this.$baseurl}/staff/profile/activate`,
          data: {
            user_id: selectedUser.id,
            ...this.getFormData(this.approvalForm)
          },
          headers: this.headers,
          success: response => {
            this.profileApprovals.map( approval => {
              if (approval.id === selectedUser.id) {
                approval.loading = false;
                approval.response = response;
              }
              return approval;
            } );
            this.getUsers();
            this.approvalForm = this.resetForm(this.approvalForm);
            this.$refs.approvalConfirmationModal.close();
          },
          error: error => {
            this.approvalForm.error = error;

            this.profileApprovals.map( approval => {
              if (approval.id === selectedUser.id) {
                approval.loading = false;
                approval.error = error;
              }
              return approval;
            } );
          }
        });

        this.approvalForm.loading = false;
        // this.profileApprovals.filter( approval => approval.id != selectedUser.id );
      },
      async deactivateProfile() {

        const selectedUser = this.selectedUser;

        this.profileApprovals = this.profileApprovals.filter(approval => approval.id != selectedUser.id);
        this.profileApprovals.push({
          id: selectedUser.id,
          action: 'deactivate',
          loading: true,
          response: null,
          error: null,
        });

        await this.$post({
          url: `${this.$baseurl}/staff/profile/deactivate`,
          data: {
            user_id: selectedUser.id
          },
          headers: this.headers,
          success: response => {
            this.profileApprovals.map( approval => {
              if (approval.id === selectedUser.id) {
                approval.loading = false;
                approval.response = response;
              }
              return approval;
            } );
            this.getUsers();
          },
          error: error => {
            this.profileApprovals.map( approval => {
              if (approval.id === selectedUser.id) {
                approval.loading = false;
                approval.error = error;
              }
              return approval;
            } );
          }
        });

        // this.profileApprovals.filter( approval => approval.id != selectedUser.id );
      },
      confirmDelete() {
        this.$refs.confirmDeleteModal.open();
      },
      async deleteuser() {
        this.deleting = true;
        await this.$_delete({
          url: `${this.$baseurl}/staff/delete/${this.selectedUser.id}`,
          headers: this.headers,
          success: () => {
            this.$refs.confirmDeleteModal.close();
            this.$refs.deleteModal.open();
            this.getUsers();
          }
        });
        this.deleting = false;
      },
      click(selectedUser) {
        this.selectedUser = this.selectedUser === selectedUser ? null : selectedUser;
        this.$refs.table.renderData();
      },
      tableRowClassName(row) {
        return row.id === this.selectedUser?.id ? 'selected' : '';
      },
      async getUsers() {
        this.users.loading = true;

        await this.sendRequest('admin.users.all', {
          success: response => {
            this.users.data = response.data.users
          },
          error: error => {
            this.users.error = error;
          }
        });

        this.users.loading = false;
      },
    }
  }
</script>