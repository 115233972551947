import Vue from "vue";

Vue.mixin({
	filters: {
		bankName(bank_code, banks = []) {

			if (!bank_code) {
				return null;
			}

			if (!banks) {
				return 'Unknown Bank';
			}

			const bank = banks.find( b => b.code === bank_code );


			return bank?.name || 'Unknown Bank';
		},
		dateFormat(date, format) {
			if (typeof date != 'string') {
				return date;
			}
			return (new Date(date)).format(format);
		},
		cardNumber(number) {
			return number.toString().replace(/(.{4})/g, '$1 ');
		},
		currency(number, places = 2) {
			// if (!number) {
			// 	return number;
			// }
			if (typeof number == "string") {
				number = number.replace(/,/g, '');
			}
			return Number(number).toFixed(places).replace(/\d(?=(\d{3})+\.)/g, '$&,');
		},
		truncate(string, length) {
			if (!string) {
				return string;
			}
			if (string.toString().length > length) {
				return string.toString().substring(0, length) + "......";
			}
			return string;
		},
		sentenceCase(string) {
			return string?.replace(/(^(\w)|\s(\w))/g, x => x.toUpperCase());
		},
		nearestWhole(number) {
			number = Number(number).toFixed().replace(',-');
			const length = number.length;
			var unit, places;

			if (length < 4) {
				return number;
			}

			if (length < 7) {
				unit = 'K';
			}else if (length < 10) {
				unit = 'M';
			}else if (length < 13) {
				unit = 'B';
			}else if (length < 16) {
				unit = 'T';
			}else if (length < 19) {
				unit = '!';
			}

			places = length % 3 == 0 ? 3 : length % 3;

			var regexp = new RegExp(`^\\d{${places}}`);

			return number.match(regexp)?.[0]+unit;

		}
	},
	listenForClick() {
		this.$el.addEventListener('click', event => {
			if (event.target.matches('[data-click]')) {
				const el = event.target;
				const pair = el.getAttribute('data-click');
				const match = pair.match(/^(\w+)\(([\s\S]*)\)/);
				if (!match) {
					throw new Error('Invalid Data-Click Value');
				}

				const method = match[1];
				const args = match[2].split(',').map(item => item.trim());

				if (!this[method]) {
					throw new Error(`Undefined method ${method}`)
				}

				this[method].apply(this, args);
			}
		});
	}
});
