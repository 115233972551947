import $ from 'jquery';
import Vue from 'vue';

Vue.mixin({
	directives: {
		fit: {
			inserted(element) {

				$(element).on('custom.update', () => {
					$(element).resizeText(element, $(element).data('fit'));
				});
				
			},
			update(element) {
				$(element).on('custom.update', () => {
					$(element).resizeText(element, $(element).data('fit'));
				});
			}
		}
	},
	mounted() {
		$(this.$el).find('[data-fit]').each( (index, element) => {
			$(element).resizeText($(element).data('fit'));
		} );
	}
});