import Vue from 'vue';

// Forms
Vue.component('checkbox',                            require('./forms/Checkbox.vue').default);
Vue.component('custom-select',                       require('./forms/Select.vue').default);
Vue.component('datepicker',                          require('vuejs-datepicker').default);
Vue.component('dropzone',                            require('./forms/DropZone.vue').default);
Vue.component('form-group',                          require('./forms/FormGroup.vue').default);
Vue.component('form-groupd',                          require('./forms/FormGroup2.vue').default);
Vue.component('money',                               require('v-money').Money);
Vue.component('paystack',                            require('./forms/Paystack.vue').default);
Vue.component('search-form',                         () => import('./forms/SearchForm.vue'));

// Presentation
Vue.component('custom-bar-chart',                    require('./presentation/BarChart.vue').default);
Vue.component('custom-slider',                       require('./presentation/CustomSlider.vue').default);
Vue.component('datatable',                           require('./presentation/Table.vue').default);
Vue.component('datatable2',                           require('./presentation/Table2.vue').default);
Vue.component('doughnut-chart',                      require('./presentation/DoughnutChart.vue').default);
Vue.component('modal',                               require('./presentation/Modal.vue').default);
Vue.component('tabs',                                require('./presentation/Tabs.vue').default);

// Cards
Vue.component('credit-card',                         require('./cards/CreditCard.vue').default);
Vue.component('credit-card-2',                       require('./cards/CreditCard2.vue').default);
Vue.component('credit-card-3',                       require('./cards/CreditCard3.vue').default);
Vue.component('credit-card-4',                       require('./cards/CreditCard4.vue').default);

// Loaders
Vue.component('loader',                              require('./loaders/Loader.vue').default);
Vue.component('sm-loader',                           require('./loaders/SmallLoader.vue').default);

// Misc
Vue.component('vector',                              require('./Vector.vue').default);