<template>
  <div class="flex">
    <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">
      <div class="flex px-10 md:px-24 justify-between">
        <h1 class="text-xl font-bold mt-5">
          Bank Account Details
        </h1>

        <div
          class="edit-button h-10 self-end flex cursor-pointer"
          @click.prevent="openEditProfile"
          v-if="!editProfile || profile"
        >
          <span
            class="text-opacity-100 text-xs font-normal px-6 self-center"
            v-if="!editProfile"
          >
            <ion-icon name="create-outline"></ion-icon>
            Edit details
          </span>
          <span
            class="text-opacity-100 text-xs font-normal px-6 self-center"
            v-else
          >
            <ion-icon name="arrow-back-outline"></ion-icon>
            Cancel
          </span>
        </div>
      </div>
      <div>
        <form
          @submit.prevent="submit"
          class="card pt-8 pb-10 px-10 md:px-24"
          key="admin"
        >
          <template v-if="getFormError(form)">
            <div class="alert alert-red-soft mb-8">
              <div class="alert-icon">!</div>
              <span>{{ getFormError(form) }}</span>
            </div>
          </template>

          <form-group
            name="account_name"
            v-model="account_name"
            :disabled="true"
            v-if="profile"
          >
            Account Name
          </form-group>

          <form-group
            name="account_no"
            :form="form"
            v-model="form.data.account_no.value"
            :disabled="check"
          >
            Account Number
          </form-group>

          <form-group
            name="bank_name"
            v-model="bank_name"
            :disabled="true"
            v-if="check"
          >
            Bank Name
          </form-group>

          <form-group
            type="select"
            :options="banks"
            name="bank_code"
            :form="form"
            v-model="form.data.bank_code.value"
            v-else
          >
            Bank Name
          </form-group>

          <div class="text-center mt-10" v-if="editProfile || !profile">
            <button
              type="submit"
              class="button bg-blue-500 hover:bg-blue-600 text-white w-full"
              @click.prevent="submitBankDetails"
            >
              <span v-if="form.loading">Submitting...</span>
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-24"
      ref="userPasswordModal"
      @close="clearForm"
    >
      <div class="text-lg font-bold mb-4 text-center">
        Provide Password to continue
      </div>
      <div class="text-sm mb-10">
        <form-groupd
          type="password"
          name="password"
          v-model="passwordForm.data.password.value"
          :data="passwordForm.data.password"
        >
          Enter Password
        </form-groupd>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn btn-blue text-center"
          @click.prevent="submit"
        >
          Confirm
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Account Addedd Successfully.
      </div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.successModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$options.basicForm([
        "bank_code",
        { name: "account_no", rules: "required|number|length:10" },
      ]),
      passwordForm: this.$options.basicForm(["password"]),
      userBankAccount: false,
      loading: false,
      error: "",
      account_number: "",
      account_name: "",
      bank_name: "",
      editProfile: false,
      profile: null,
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks.map((bank) => ({
        title: bank.name,
        value: bank.code,
      }));
    },
    check() {
      return !!(!this.editProfile && this.profile)
    }
  },
  async beforeMount() {
    this.getBankDetails();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    openEditProfile() {
      this.editProfile = !this.editProfile;
    },
    clearForm() {
      this.resetForm(this.passwordForm);
    },
    showPaymentForm() {
      this.userBankAccount = false;
    },
    submitBankDetails() {
      if (!this.validateForm(this.form)) {
        return false;
      }
      this.$refs.userPasswordModal.open();
    },
    async submit() {
      this.$refs.userPasswordModal.close();
      const bankCode = this.getFormData(this.form).bank_code;

      const bankName = this.$store.state.banks.find(
        (bank) => bank.code === bankCode
      );

      this.form.loading = true;
      await this.sendRequest(`affiliate.payment.new`, {
        data: {
          ...this.getFormData(this.form),
          bank_name: bankName.name,
          ...this.getFormData(this.passwordForm),
        },
        success: () => {
          this.$refs.successModal.open();
          this.getBankDetails();
          this.form = this.resetForm(this.form);
        },
        error: (error) => {
          this.form.error = error;
          this.passwordForm = this.resetForm(this.passwordForm);
          console.log(45, error);
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
      this.form.loading = false;
    },

    async getBankDetails() {
      await this.sendRequest("affiliate.payment.getBankDetails", {
        success: (response) => {
          const { data } = response.data;
          if (typeof data === "object" && data?.bank_name) {
            this.userBankAccount = true;
            this.bank_name = data.bank_name;
            this.account_number = data.account_no;
            this.account_name = data.account_name;
            this.form.data.account_no.value = data.account_no;
            this.profile = data;
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.edit-button {
  background-color: #f5f7fb;
  color: #6e7ab6;
}
</style>
