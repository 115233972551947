<template>
	<div>
	
		<div class="font-medium text-xl mb-10">Add a single user</div>

		<form @submit.prevent="submit" key="company">

			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft mb-10">
					<span class="alert-icon">!</span>
					<span>{{ getFormError(form) }}</span>
				</div>
			</template>
			
			<div class="grid grid-cols-1 sm: grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group
						leftIcon="person-outline"
						name="first_name"
						:form="form"
						v-model="form.data.first_name.value"
					>
						First Name
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group
						leftIcon="person-outline"
						name="last_name"
						:form="form"
						v-model="form.data.last_name.value"
					>
						Last Name
					</form-group>
				</div>
			</div>

			<form-group type="email"
				leftIcon="mail-outline"
				name="email"
				:form="form"
				v-model="form.data.email.value"
			>
				Email Address
			</form-group>

			<form-group
				type="phone"
				leftIcon="call-outline"
				name="phone"
				:form="form"
				v-model="form.data.phone.value"
			>
				Phone Number
			</form-group>

			<!-- <div class="flex flex-row items-center mb-6">
				<checkbox id="is_admin" class="mr-3" v-model="form.data.is_admin.value" />
				<label for="is_admin" class="text-xs">Assign Admin Role</label>
			</div>

			<template v-if="wantsAdmin">
				<form-group
					type="select"
					:options="roles"
					left-icon="person-outline"
					name="role"
					v-model="form.data.role.value"
					:form="form"
				>
					Role
				</form-group>
			</template> -->

			<div class="text-right">
				<button type="submit" class="btn btn-blue" :disabled="form.loading">
					<span v-if="form.loading">Adding...</span>
					<span v-else>Add User</span>
				</button>
			</div>

		</form>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				User Created Successfully
			</div>

			<router-link :to="{name: 'users'}" class="btn btn-blue btn-md">
				View all Users
			</router-link>
			
		</modal>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				checkmark: require('@/assets/checkmark-base.svg'),
				email: '',
				form: this.$options.basicForm([
					{name: 'first_name', rules: 'required|aplpha'},
					{name: 'last_name', rules: 'required|aplpha'},
					{name: 'email', rules: 'required|email'},
					{name: 'phone', rules: 'required|phone'},
					// {name: 'group', rules: 'required'},
					// {name: 'role', rules: 'required', value: 'company_staff'},
					// {name: 'is_admin', rules: 'nullable', value: false},
					// {name: 'account_no', rules: 'required|number|length:10'},
					// {name: 'bank_name', rules: 'required'},
				]),
				roles: [
					{title: 'Super Admin', value: 'company_super'},
					{title: 'HR', value: 'company_hr'},
					{title: 'Finance Manager', value: 'company_finance'},
					{title: 'Audit', value: 'company_audit'},
					{title: 'Department Head', value: 'company_department_head'},
					{title: 'Unit Head', value: 'company_unit_head'},
					// {title: 'Employee', value: 'company_staff'},
				],
			}
		},
		computed: {
			// wantsAdmin() {
			// 	return this.form.data.is_admin.value;
			// }
		},
		beforeMount() {
			// this.getGroups();
		},
		// watch: {
		// 	'form.data.is_admin.value'(is_admin) {
		// 		if (is_admin) {
		// 			this.form.data.role.value = '';
		// 		}else {
		// 			this.form.data.role.value = 'company_staff';
		// 		}
		// 	}
		// },
		methods: {
			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.error = false;
				this.form.loading = true;

				await this.sendRequest('admin.users.new', {
					data: {
						...this.getFormData(this.form),
						// account_no: this.form.data.account_no.value,
						// bank_name: this.form.data.bank_name.value,
						companyid: this.user.company_id
					},
					success: () => {
						this.$refs.successModal.open();
						this.form = this.resetForm(this.form);
					},
					error: error => {
						this.form.error = error;

						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			},
		}
	}
</script>