<template>
  <div class="-mx-10 -mt-8 h-screen">
    <div class="border-t border-blue-200 h-full">
      <history />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    history: require("./WithdrawalRequest").default,
  },
};
</script>
