<template>
  <aside class="sidebar">
    <div class="logo">
      <img :src="logo" alt="CredPal" class="mr-2" />
      <!-- <div class="text-xs opacity-25">Referrals</div> -->
      <div
        class="text-xs text-white bg-gray-500 py-1 px-3 w-auto self-start rounded -mt-2"
      >
        Referrals
      </div>
    </div>
    <div class="flex-auto overflow-y-auto" id="index-sidebar">
      <user />
    </div>
  </aside>
</template>

<script>
export default {
  components: {
    user: require("./User.vue").default,
  },
  data() {
    return {
      // logo: require('@/assets/logo.svg')
    };
  },
  computed: {
    logo() {
      return require("@/assets/logo-blue.svg");
    },
  },
  methods: {
    collapse(element) {
      window.collapse(element, "toggle");
    },
  },
};
</script>

<style lang="scss">
#index-sidebar::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

#index-sidebar::-webkit-scrollbar-thumb {
  background-color: rgb(95, 126, 250);
  border-radius: 10px;
}
</style>
