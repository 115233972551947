import Vue from 'vue';
import { mapState } from 'vuex';

Vue.mixin({
	computed: {
		...mapState('session', ['user']),

		accountType() {
			return this.$route.params.accountType;
		},
		appMode() {
			switch (this.accountType) {
				case process.env.VUE_APP_ADMIN_PATH:
					return 'super';  
				case process.env.VUE_APP_CORPORATE_PATH:
					return 'company';
				case process.env.VUE_APP_CUSTOMER_PATH:
					return 'staff';
				default:
					return null;
			}
		},
		firstName() {
			if (!this.user) {
				return null;
			}
			return this.user.first_name;			
		},
		firstLetter() {
			return this.firstName ? this.firstName[0] : null;
		},
		isCredpalAdmin() {
			return this.isSuperAdmin || this.isSuperSalesAgent;
		},
		isCompanyAdmin() {
			return this.$store.state.session.roles?.find(role => role.slug.match(/^company_/) && !role.slug.match(/company_staff|company_department_head|company_unit_head/))
		},
		isCompanyStaff() {
			return this.$store.state.session.roles?.find(role => role.slug.match(/company_staff|company_department_head|company_unit_head/));
		},
		isCompanyFinanceManager() {
			return this.$store.state.session.roles?.find(role => role.slug === 'company_finance_manager');
		},
		isCompanyHR() {
			return this.$store.state.session.roles?.find(role => role.slug === 'company_hr');
		},
		isSuperAdmin() {
			return this.$store.state.session.roles?.find(role => role.slug === 'super_admin');
		},
		isSuperSalesAgent() {
			return this.$store.state.session.roles?.find(role => role.slug === 'super_sales_agent');
		},
		lastName() {
			if (!this.user) {
				return null;
			}
			return this.user.last_name;			
		},
		timeOfDay() {
			const currentHour = (new Date).getHours();

			if (currentHour >= 0 && currentHour < 12) {
				return 'Morning';
			}

			if (currentHour >= 12 && currentHour < 18) {
				return 'Afternoon';
			}

			return 'Evening';
		},
		ENV_VUE_APP_ADMIN_PATH() {
			return process.env.VUE_APP_ADMIN_PATH;
		},
		ENV_VUE_APP_CORPORATE_PATH() {
			return process.env.VUE_APP_CORPORATE_PATH;
		},
		ENV_VUE_APP_CUSTOMER_PATH() {
			return process.env.VUE_APP_CUSTOMER_PATH;
		},
	},
	methods: {
		canAccessAny(routeNames) {
			return routeNames.filter( routeName => this.canAccess(routeName) ).length > 0;
		},
	},
});