<template>
  <div class="data-table">
    <div class="data-table-inner">
      <!-- Datatable Header -->
      <template v-if="header">
        <slot name="header">
          <!-- Row One -->
          <div class="grid grid-cols-1 md:grid-cols-2">
            <!-- Table Export -->
            <template v-if="exportable">
              <div class="col-span-1 data-table-control mb-3 ml-auto">
                <!-- Export Original Data -->
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="exportAll"
                >
                  Export All
                </button>
                <!-- Export Original Data -->

                <!-- Export Rendered Data -->
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="exportData"
                >
                  Export
                </button>
                <!-- Export Rendered Data -->
              </div>
            </template>
            <!-- Table Export -->
          </div>
          <!-- Row One -->

          <!-- Row Two -->
          <div class="grid grid-cols-1 md:grid-cols-2">
            <!-- Limitable -->
            <template v-if="limitable">
              <div class="col-span-1 data-table-control mb-3">
                <div class="form-group">
                  <label>
                    Show &nbsp;
                    <select
                      type="text"
                      class="custom-select custom-select-sm"
                      v-model="itemsPerPage"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                    </select>
                    &nbsp; Results
                  </label>
                </div>
              </div>
            </template>
            <!-- Limitable -->

            <!-- Searchable -->
            <template v-if="searchable">
              <div class="col-span-1 data-table-control mb-3">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Search Records"
                    @keyup.enter="searchEnterKey"
                    v-model="query"
                  />
                </div>
              </div>
            </template>
            <!--   -->
          </div>
          <!-- Row Two -->

          <!-- Row Three -->
          <div class="grid grid-cols-1 md:grid-cols-2">
            <!-- Rangeable -->
            <!-- <template v-if="rangeable">
							<div class="col-span-1 data-table-control mb-3">
								<date-range-picker
									ref="picker"
									:locale-data="{ firstDay: 1, format: dateRange.format }"
									:singleDatePicker="dateRange.singleDatePicker"
									:autoApply="dateRange.autoApply"
									v-model="dateRange.value"
									@update="dateRange.updateValues"
									@toggle="() => {}"
								/>
							</div>
						</template> -->
            <!-- Rangeable -->

            <!-- Filterable -->
            <template v-if="filterable && filters.length">
              <div class="col-span-1 data-table-control mb-3 ml-auto">
                <!-- Using Dropdown -->
                <template v-if="canUseDropdownForFilters">
                  <div class="dropdown dropleft">
                    <button type="button" class="btn btn-primary" dropdown>
                      Filters
                    </button>
                    <ul class="dropdown-menu">
                      <template v-for="(filter, n) in filters">
                        <a
                          href="javascript: void(0)"
                          class="dropdown-menu-item"
                          :class="{
                            active: currentFilter === filter,
                          }"
                          :key="n"
                          @click.prevent="clickedFilter(filter)"
                          v-html="filter.title"
                        ></a>
                      </template>
                    </ul>
                  </div>
                </template>
                <!-- Using Dropdown -->

                <!-- Using Tags -->
                <template v-else>
                  <div class="mt-3 w-full">
                    <span class="ml-3 mb-5">Filters:</span>
                    <div class="flex flex-row">
                      <template v-for="(filter, n) in filters">
                        <a
                          href="javascript: void(0)"
                          class="filter-class"
                          :class="{
                            active: currentFilter === filter,
                          }"
                          :key="n"
                          @click.prevent="clickedFilter(filter)"
                          v-html="filter.title"
                        ></a>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- Using Tags -->
              </div>
            </template>
            <!-- Filterable -->
          </div>
          <!-- Row Three -->
        </slot>
      </template>
      <!-- Datatable Header -->

      <!-- Datatable Body -->
      <template>
        <div class="datatable-content mb-6">
          <!-- Loading State -->
          <template v-if="isLoading">
            <slot name="loading">
              <!-- <loader /> -->
              <div class="data-table-loading">
                <div class="data-table-loading-spinner"></div>
                <div class="data-table-loading-text">
                  Loading Data
                </div>
              </div>
            </slot>
          </template>
          <!-- Loading State -->

          <!-- Table Content -->
          <template v-else>
            <div class="table-responsive">
              <table
                class="table"
                :class="{
                  'table-interactable': clickable,
                  [tableStyle]: true,
                }"
              >
                <!-- Table Header -->
                <thead v-if="showHeadings">
                  <tr>
                    <!-- Selectable Heading -->
                    <template v-if="selectable">
                      <th>
                        <label class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            @change="selectAll"
                            ref="selectAll"
                          />
                          <span class="custom-control-label"></span>
                        </label>
                      </th>
                    </template>
                    <!-- Selectable Heading -->

                    <!-- Row Index Heading -->
                    <template v-if="index">
                      <th
                        @click="sortIndex()"
                        class="sortable"
                        :class="{
                          sort: sortColumn === '#',
                          asc: sortColumn === '#' && asc,
                          desc: sortColumn === '#' && !asc,
                        }"
                      >
                        #
                        <span class="sort-icon"></span>
                      </th>
                    </template>
                    <!-- Row Index Heading -->

                    <!-- Render Table Headings -->
                    <template v-for="(th, n) in headings">
                      <template v-if="th.sortable">
                        <th
                          :align="th.align"
                          :class="{
                            // [th.class]: true,
                            sortable: true,
                            sort: sortColumn === th.name,
                            asc: sortColumn === th.name && asc,
                            desc: sortColumn === th.name && !asc,
                          }"
                          v-if="th.show"
                          :key="n"
                          @click="clickedHeader(th)"
                        >
                          <span v-html="th.title"></span>
                          <span class="sort-icon"></span>
                        </th>
                      </template>
                      <template v-else>
                        <th
                          :align="th.align"
                          v-if="th.show"
                          :key="n"
                          @click="clickedHeader(th)"
                          v-html="th.title"
                        ></th>
                      </template>
                    </template>
                    <!-- Render Table Headings -->

                    <!-- Actions Heading -->
                    <template v-if="actions.length">
                      <th>
                        {{ actionsHeader }}
                      </th>
                    </template>
                    <!-- Actions Heading -->
                  </tr>
                </thead>
                <!-- Table Header -->

                <!-- Table Body -->
                <template v-if="paginatedItems.length">
                  <!-- Groupable -->
                  <template v-if="groupable">
                    <template v-for="(group, n) in groups">
                      <tbody :key="n">
                        <tr>
                          <th :colspan="colspan">
                            {{ group.name }} -
                            {{ group.items.length }}
                            item{{ group.items.length !== 1 ? "s" : "" }}
                          </th>
                        </tr>

                        <!-- Table Rows -->
                        <template v-for="(item, n) in group.items">
                          <tr :class="item.class" :key="n">
                            <!-- Selectable Toggle -->
                            <template v-if="selectable">
                              <td>
                                <div
                                  class="custom-control custom-checkbox"
                                  @click="selectItem(item)"
                                >
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    :checked="item.selected"
                                  />
                                  <span class="custom-control-label"></span>
                                </div>
                              </td>
                            </template>
                            <!-- Selectable Toggle -->

                            <!-- Row Index -->
                            <template v-if="index">
                              <td>
                                {{
                                  reverseIndex
                                    ? renderedItems.length - item.index
                                    : item.index + 1
                                }}
                              </td>
                            </template>
                            <!-- Row Index -->

                            <!-- Table Data -->
                            <template v-for="(td, o) in item.details">
                              <td
                                :align="td.align"
                                :class="td.class"
                                v-if="td.show"
                                :key="o"
                                @click="clickedTD(td, n)"
                              >
                                <slot :name="`td-${o + 1}`">
                                  <div
                                    v-html="td.rendered"
                                    v-if="
                                      td.rendered !== null && td.rendered !== ''
                                    "
                                  ></div>
                                  <div v-else>
                                    ---
                                  </div>
                                </slot>
                              </td>
                            </template>
                            <!-- Table Data -->

                            <!-- Actions -->
                            <template v-if="actions.length">
                              <td>
                                <!-- Using Dropdown -->
                                <template v-if="canUseDropdownForActions">
                                  <div class="dropdown">
                                    <button
                                      type="button"
                                      class="dropdown btn btn-primary"
                                      data-toggle="dropdown"
                                      :disabled="
                                        !item.buttons.length || disableActions
                                      "
                                    >
                                      <!-- Actions Available -->
                                      <template v-if="item.buttons.length">
                                        Actions
                                      </template>
                                      <!-- Actions Available -->

                                      <!-- No Available Actions -->
                                      <template v-else>
                                        Unavailable
                                      </template>
                                      <!-- No Available Actions -->
                                    </button>
                                    <ul
                                      class="dropdown-menu left"
                                      data-boundary=".table-responsive"
                                    >
                                      <template
                                        v-for="(action, o) in item.buttons"
                                      >
                                        <a
                                          href="javascript: void(0)"
                                          :class="action.class"
                                          :disabled="
                                            action.disabled || disableActions
                                          "
                                          v-if="action.show"
                                          :key="o"
                                          @click.prevent="
                                            clickedAction(action, n)
                                          "
                                          v-html="action.text"
                                        ></a>
                                      </template>
                                    </ul>
                                  </div>
                                </template>
                                <!-- Using Dropdown -->

                                <!-- Using Button Group -->
                                <template v-else>
                                  <div class="btn-group">
                                    <template
                                      v-for="(action, o) in item.buttons"
                                    >
                                      <button
                                        :class="action.class"
                                        :disabled="
                                          action.disabled || disableActions
                                        "
                                        v-if="action.show"
                                        :key="o"
                                        @click.prevent="
                                          clickedAction(action, n)
                                        "
                                        v-html="action.text"
                                      ></button>
                                    </template>
                                  </div>
                                </template>
                                <!-- Using Button Group -->
                              </td>
                            </template>
                            <!-- Actions -->
                          </tr>
                        </template>
                        <!-- Table Rows -->
                      </tbody>
                    </template>
                    <template v-for="(emptyRow, n) in missingRows">
                      <tr :key="`empty-${n}`">
                        <td :colspan="colspan"></td>
                      </tr>
                    </template>
                  </template>
                  <!-- Groupable -->

                  <!-- Not Groupable -->
                  <template v-else>
                    <tbody>
                      <!-- Table Rows -->
                      <template v-for="(item, n) in paginatedItems">
                        <tr :class="item.class" :key="n">
                          <!-- Selectable Toggle -->
                          <template v-if="selectable">
                            <td>
                              <div
                                class="custom-control custom-checkbox"
                                @click="selectItem(item)"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :checked="item.selected"
                                />
                                <span class="custom-control-label"></span>
                              </div>
                            </td>
                          </template>
                          <!-- Selectable Toggle -->

                          <!-- Row Index -->
                          <template v-if="index">
                            <td>
                              {{
                                reverseIndex
                                  ? renderedItems.length - item.index
                                  : item.index + 1
                              }}
                            </td>
                          </template>
                          <!-- Row Index -->

                          <!-- Table Data -->
                          <template v-for="(td, o) in item.details">
                            <td
                              :align="td.align"
                              :class="td.class"
                              v-if="td.show"
                              :key="o"
                              @click="clickedTD(td, n)"
                            >
                              <slot :name="`td-${o + 1}`">
                                <div
                                  v-html="td.rendered"
                                  v-if="
                                    td.rendered !== null && td.rendered !== ''
                                  "
                                ></div>
                                <div v-else>
                                  ---
                                </div>
                              </slot>
                            </td>
                          </template>
                          <!-- Table Data -->

                          <!-- Actions -->
                          <template v-if="actions.length">
                            <td>
                              <!-- Using Dropdown -->
                              <template v-if="canUseDropdownForActions">
                                <div class="dropdown inline-block">
                                  <button
                                    type="button"
                                    class="px-6 py-3 rounded border border-transparent focus:outline-none focus:border focus:border-blue-200"
                                    dropdown
                                    :disabled="
                                      !item.buttons.length || disableActions
                                    "
                                  >
                                    <!-- Actions Available -->
                                    <template v-if="item.buttons.length">
                                      <div class="flex flex-col">
                                        <div
                                          class="h-1 w-1 bg-gray-400 mb-1 rounded-full"
                                        ></div>
                                        <div
                                          class="h-1 w-1 bg-gray-400 mb-1 rounded-full"
                                        ></div>
                                        <div
                                          class="h-1 w-1 bg-gray-400 rounded-full"
                                        ></div>
                                      </div>
                                    </template>
                                    <!-- Actions Available -->

                                    <!-- No Available Actions -->
                                    <template v-else>
                                      Unavailable
                                    </template>
                                    <!-- No Available Actions -->
                                  </button>
                                  <ul
                                    class="dropdown-menu left"
                                    data-boundary=".table-responsive"
                                  >
                                    <template
                                      v-for="(action, o) in item.buttons"
                                    >
                                      <a
                                        href="javascript: void(0)"
                                        :class="action.class"
                                        :disabled="
                                          action.disabled || disableActions
                                        "
                                        v-if="action.show"
                                        :key="o"
                                        @click.prevent="
                                          clickedAction(action, n)
                                        "
                                        v-html="action.text"
                                      ></a>
                                    </template>
                                  </ul>
                                </div>
                              </template>
                              <!-- Using Dropdown -->

                              <!-- Using Button Group -->
                              <template v-else>
                                <div class="btn-group">
                                  <template v-for="(action, o) in item.buttons">
                                    <button
                                      :class="action.class"
                                      :disabled="
                                        action.disabled || disableActions
                                      "
                                      v-if="action.show"
                                      :key="o"
                                      @click.prevent="clickedAction(action, n)"
                                      v-html="action.text"
                                    ></button>
                                  </template>
                                </div>
                              </template>
                              <!-- Using Button Group -->
                            </td>
                          </template>
                          <!-- Actions -->
                        </tr>
                      </template>
                      <template v-for="(emptyRow, n) in missingRows">
                        <tr class="empty" :key="`empty-${n}`">
                          <td :colspan="colspan"></td>
                        </tr>
                      </template>
                      <!-- Table Rows -->
                    </tbody>
                  </template>
                  <!-- Not Groupable -->
                </template>

                <template v-else>
                  <tbody>
                    <tr>
                      <td align="center" :colspan="colspan">
                        <slot name="empty">
                          <!-- <img :src="emptyImg" alt="No Results" class="w-full max-h-500px p-10"> -->
                          No Results
                        </slot>
                      </td>
                    </tr>
                  </tbody>
                </template>

                <!-- Table Body -->
              </table>
            </div>
          </template>
          <!-- Table Content -->
        </div>
      </template>
      <!-- Datatable Body -->

      <!-- Datatable Footer -->
      <template v-if="footer">
        <slot name="footer">
          <!-- Row Four -->
          <div class="grid grid-cols-1 md:grid-cols-2">
            <!-- Page Details -->
            <template v-if="pageDetails">
              <div class="col-span-1 data-table-control mb-3">
                Showing {{ displayStats.start }} to {{ displayStats.end }} of
                {{ displayStats.total }} items.
              </div>
            </template>
            <!-- Page Details -->

            <!-- Paginatable -->
            <template v-if="paginatable && pages > 1">
              <div class="col-span-1 data-table-control mb-3 ml-auto">
                <ul class="pagination" v-if="paginationLinks.length">
                  <li class="page-item" v-if="pages && currentPage != 1">
                    <span class="page-link" @click="prev">Prev</span>
                  </li>
                  <li
                    class="page-item"
                    :class="{
                      active: currentPage === item.page,
                    }"
                    v-for="item in paginationLinks"
                    v-bind:key="item.page"
                  >
                    <span class="page-link" @click="navigate(item.page)">{{
                      item.page
                    }}</span>
                  </li>
                  <li class="page-item" v-if="pages && currentPage < pages">
                    <span class="page-link" @click="next">Next</span>
                  </li>
                </ul>
              </div>
            </template>
            <!-- Paginatable -->
          </div>
          <!-- Row Four -->
        </slot>
      </template>
      <!-- Datatable Footer -->
    </div>
  </div>
</template>

<script>
/*
	|-	----------------------------------------------------------------------------------------------------
	|	Dependencies
	|-	----------------------------------------------------------------------------------------------------
	*/
import axios from "axios";
import $ from "jquery";
import toastr from "toastr";

export default {
  name: "Vue-DataTable",

  components: {
    // dateRangePicker: require('vue2-daterange-picker').default
  },

  model: {
    prop: "selected",
    event: "select",
  },

  props: {
    // Content
    actions: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    itemProps: {
      type: Object,
      default: () => ({}),
    },
    limit: {
      type: Number,
      default: 20,
    },

    // Display Config
    actionsHeader: {
      type: String,
      default: "Actions",
    },
    breakWords: {
      type: Boolean,
      default: true,
    },
    className: {
      type: Function,
      default: () => "",
    },
    dropdown: {
      type: [Boolean, String],
      default: true,
    },
    index: {
      type: Boolean,
      default: false,
    },
    totalIndex: {
      type: Boolean,
      default: false,
    },
    reverseIndex: {
      type: Boolean,
      default: false,
    },

    // Interaction Config
    disableActions: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    useQuery: {
      type: Boolean,
      default: false,
    },
    fullTextSearch: {
      type: Boolean,
      default: false,
    },

    // Server Information
    ajax: {
      type: Boolean,
      default: false,
    },
    // ajaxHeaders: {
    // 	type: Object,
    // 	default: () => ({})
    // },
    ajaxPagination: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },

    // Features
    exportable: {
      type: Boolean,
      default: false,
    },
    fillable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    groupable: {
      type: [Object, String],
      default: null,
    },
    limitable: {
      type: Boolean,
      default: false,
    },
    pageDetails: {
      type: Boolean,
      default: false,
    },
    paginatable: {
      type: Boolean,
      default: true,
    },
    query: {
      type: String,
      default: "",
    },
    rangeable: {
      type: [Object, String],
      default: null,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showHeadings: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    tableStyle: {
      type: String,
      default: "",
    },

    // Sections
    footer: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // Content
      ajaxItems: [],
      items: [],
      headings: [],
      renderedItems: [],
      emptyImg: require("@/assets/empty-delivery.svg"),

      // State
      ajaxLoading: false,
      ajaxObject: null,
      asc: true,
      currentFilter: null,
      currentPage: 1,
      dateRange: {
        value: {
          startDate: null,
          endDate: null,
        },
        format: "dd/mm/yyyy",
      },
      exportType: null,
      itemsPerPage: 20,
      lastSortColumn: null,
      selected: [],
      sortColumn: null,
    };
  },

  computed: {
    // Booleans
    canUseDropdownForActions() {
      return this.dropdown === true || this.dropdown === "actions";
    },
    canUseDropdownForFilters() {
      return this.dropdown === true || this.dropdown === "filters";
    },
    colspan() {
      return (
        this.headings.length +
        (this.actions.length ? 1 : 0) +
        (this.index ? 1 : 0) +
        (this.selectable ? 1 : 0)
      );
    },
    clickable() {
      return this.onClick !== null;
    },
    displayStats() {
      return {
        end: this.paginatedItems.length
          ? this.paginationEnd > this.paginatedItems.length
            ? this.paginatedItems.length
            : this.paginationEnd
          : 0,
        start: this.paginatedItems.length ? this.paginationStart + 1 : 0,
        total:
          this.ajaxPagination && this.ajaxObject
            ? this.ajaxObject.total_count
            : this.renderedItems.length,
      };
    },
    groupableKey() {
      if (!this.groupable) return null;

      switch (this.groupable.constructor) {
        case String:
          return this.groupable;
        case Object:
          return this.groupable.key;
        default:
          return null;
      }
    },
    groupNameDeterminer() {
      return typeof this.groupable === "object" ? this.groupable.name : null;
    },
    groups() {
      const groups = [];

      if (!this.groupable) {
        return groups;
      }

      const groupKey = this.groupableKey;
      const randomGroupName = Math.round(Math.random() * 100000);

      this.paginatedItems.forEach((item) => {
        let groupName = item.row[groupKey];
        if (!groupName) {
          groupName = randomGroupName;
        }

        if (!groups.find((group) => group.name === groupName)) {
          groups.push({
            name: groupName,
            items: [item],
          });
        } else {
          groups.forEach((group) => {
            if (group.name === groupName) {
              group.items.push(item);
            }
          });
        }
      });

      return groups.map((group) => {
        return {
          ...group,
          name: this.groupNameDeterminer
            ? this.groupNameDeterminer(
                group,
                group.items.map((item) => item.row)
              )
            : group.name,
        };
      });
    },
    isLoading() {
      return this.loading || this.ajaxLoading;
    },
    missingRows() {
      return this.fillable
        ? this.renderedItems.length >= 10
          ? 0
          : 10 - this.renderedItems.length
        : 0;
    },
    pages() {
      if (!this.ajaxPagination) {
        if (this.renderedItems.length > this.itemsPerPage) {
          return Math.ceil(this.renderedItems.length / this.itemsPerPage);
        } else {
          return 0;
        }
      }

      return this.ajaxObject ? this.ajaxObject.page_count : 0;
    },
    paginationStart() {
      return this.itemsPerPage * (this.currentPage - 1);
    },
    paginationEnd() {
      return this.itemsPerPage * this.currentPage;
    },
    paginatedItems() {
      var items = [...this.renderedItems];
      if (this.ajaxPagination) {
        return items;
      }
      return items.slice(this.paginationStart, this.paginationEnd);
    },
    paginationLinks() {
      const links = [];
      const center = Math.round(this.pages / 2) - 1;
      for (var i = 0; i < this.pages; i++) {
        if (this.pages > 6) {
          let difference = this.currentPage - i;
          // let centerDifference = center - i;
          // around the current page
          if (!(difference < 0) && !(difference > 2)) {
            // around the center
          } else if (i === center) {
            // at the start or end
          } else if (this.pages - i <= 2 || i <= 1) {
            // everywhere else
          } else {
            continue;
          }
        }
        links.push({ page: i + 1 });
      }
      return links;
    },
    // Strings
    processedUrl() {
      const baseUrl = this.url.replace(/\/$/g, "");
      return `${baseUrl}?${this.queryString}`;
    },
    queryString() {
      const rangeKey =
        this.rangeable && typeof this.rangeable === "object"
          ? this.rangeable.key
          : this.rangeable;
      var rangeStart = this.dateRange.value.startDate;
      if (rangeStart) {
        let date = new Date(rangeStart);
        rangeStart = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
      }
      var rangeEnd = this.dateRange.value.endDate;
      if (rangeEnd) {
        let date = new Date(rangeEnd);
        rangeEnd = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
      }

      var obj = {
        filter: this.currentFilter ? this.currentFilter.title : "",
        page: this.currentPage,
        limit: this.itemsPerPage,
        search: this.query,
        order: this.asc ? "asc" : "desc",
        sort_column: this.sortColumn,
        fullTextSearch: this.fullTextSearch ? 1 : 0,
        rangeEnd,
        rangeKey,
        rangeStart,
        exportType: this.exportType,
      };

      var query_string = Object.keys(obj)
        .map((key) => {
          if (obj[key]) {
            return `${key}=${obj[key]}`;
          }
        })
        .filter((key) => !!key)
        .join("&");

      return query_string;
    },
  },

  watch: {
    ajaxItems() {
      this.renderData();
    },
    data() {
      this.items = this.data;
    },
    items() {
      this.renderData();
    },
    limit(value) {
      this.itemsPerPage = value;
    },
    selected() {
      this.renderData();
    },

    currentPage() {
      if (this.ajaxPagination) {
        this.getData();
      }
    },
    query() {
      this.renderData();
    },

    "dateRange.value"() {
      if (this.ajaxPagination) {
        this.getData();
      } else {
        this.renderData();
      }
    },
  },

  /*
		|-	----------------------------------------------------------------------------------------------------
		|	Life Cycle Events
		|-	----------------------------------------------------------------------------------------------------
		|
		*/
  mounted() {
    this.itemsPerPage = this.limit;
    this.init();
    this.DOMListener();
  },

  beforeDestroy() {},

  destroyed() {},
  /*
		|
		|-	----------------------------------------------------------------------------------------------------
		*/

  methods: {
    /*
			|-	----------------------------------------------------------------------------------------------------
			|	HTTP Request Methods
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    async get(url, { success }) {
      await axios({
        url,
        method: "GET",
        headers: this.headers,
      })
        .then((response) => {
          if (!response.data) {
            throw new Error("No data returned");
          }
          success(response.data);
        })
        .catch((error) => {
          this.error(error || "Unable to load data");
        });
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Data Loaders
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    async getData() {
      this.ajaxLoading = true;

      await this.get(this.processedUrl, {
        success: (data) => {
          var items;
          switch (data.constructor) {
            case Array:
              items = data;
              break;
            case Object:
              if (this.ajaxPagination) {
                this.ajaxObject = data.datatable;
                items = this.ajaxObject.data;
              } else {
                items = data.data;
              }
              break;
            default:
              throw new Error("Unknown response type");
          }
          this.ajaxItems = items;
        },
      });

      this.ajaxLoading = false;
    },
    loadAjaxData() {
      this.getData();
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Function
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    init() {
      this.renderHeaders();

      if (this.ajax) {
        this.useAjax();
      } else {
        this.usePropsData();
      }
    },
    renderData() {
      var items = this.ajax ? this.ajaxItems : this.data;

      items = this.mapItems(items);

      if (!this.ajaxPagination) {
        // Apply Filters
        items = this.filterData(items);

        // Search Through
        items = this.searchData(items);

        // Sort Data
        items = this.sortData(items);

        // Get Desired Range
        items = this.rangeData(items);
      }

      this.renderedItems = items;
    },
    renderHeaders() {
      this.headings = this.columns.map((column) => ({
        align: column.align || "left",
        name: column.name,
        show:
          typeof column.show === "function"
            ? column.show()
            : column.show !== false,
        title: column.th,
        sortable: column.sortable !== undefined ? column.sortable : true,
      }));
    },
    async useAjax() {
      await this.getData();
    },
    usePropsData() {
      this.items = this.data;
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Selectable
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    clearSelection() {
      this.selected = [];
      this.renderedItems.forEach((item) => {
        item.selected = false;
      });
      this.$refs.selectAll.checked = false;
      this.$emit("select", this.selected);
    },
    selectItem(item) {
      if (item.selected) {
        item.selected = false;
        this.selected = this.selected.filter((i) => item.index != i.index);
        this.$refs.selectAll.checked = false;
      } else {
        item.selected = true;
        this.selected.push(item);
      }
      this.$emit("select", this.selected);
    },
    selectAll(event) {
      if (event.target.checked) {
        this.renderedItems.forEach((item) => {
          item.selected = true;
          this.selected = this.selected.filter((s) => s.index != item.index);
          this.selected.push(item);
        });
      } else {
        this.selected = [];
        this.renderedItems.forEach((item) => {
          item.selected = false;
        });
      }

      this.$emit("select", this.selected);
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Exportable
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    exportAll() {
      this.exportType = "all";

      // this.updateQuery();

      var url = this.url + "?" + this.queryString;

      window.open(url);

      this.exportType = null;

      // this.updateQuery();
    },
    exportData() {
      this.exportType = "with-filters";

      // this.updateQuery();

      var url = this.url + "?" + this.queryString;

      window.open(url);

      this.exportType = null;

      // this.updateQuery();
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Processors
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    filterData(items) {
      var filter = this.currentFilter;
      if (!filter) {
        return items;
      }

      var filterValue = filter.value,
        filterColumn = filter.name;

      items = items.filter((item, index) => {
        var column = item.details.find(
          (column) => column.name === filterColumn
        );
        // If Value Type Is A Custom Function
        if (filterValue.constructor.toString().match(/Function/)) {
          if (filterValue(item.row, (column && column) || null, index)) {
            return true;
          }
        }
        if (!column) {
          return false;
        }
        if (column.value === filterValue || column.rendered === filterValue) {
          return true;
        }
        return false;
      });

      return items;
    },
    mapItems(items) {
      items = items.map((item, index) => {
        // Row Item
        var row = {
          row: item,
          details: [],
          index,
          buttons: [],
          selected: !!this.selected.find((a) => a.index === index),
          class: this.className ? this.className(item, index) : "",
        };
        // ...this.itemProps
        for (var key in this.itemProps) {
          item[key] = this.itemProps[key];
        }

        // Get Provided Columns
        this.columns.forEach((column) => {
          row.details.push({
            // Item Column Name
            name: column.name,
            // Table Header Title
            th: column.th,
            // Provided Value
            value: item[column.name],
            // Decide Value Depending On Whether Render Method Is Provided
            rendered: column.render
              ? column.render(item, item[column.name], index)
              : item[column.name],
            // Origin Item Row
            row: item,
            // Whether Or Not To Display Item
            align: column.align || "left",
            // Whether Or Not To Display Item
            show:
              typeof column.show === "function"
                ? column.show()
                : column.show !== false,
            // Click Event For Column
            click: column.click,
            // Table Cell Class
            class:
              typeof column.className === "function"
                ? column.className()
                : column.className,
          });
        });

        // Get Provided Actions
        this.actions.forEach((button) => {
          var className;
          if (this.canUseDropdownForActions) {
            className = "dropdown-menu-item";
            className += " " + button.class;
          } else {
            // className = 'btn';
            className = button.class;
            // className += (button.color ? ` btn-${button.color}` : '');
            // className += (button.size ? ` btn-${button.size}` : '');
          }

          row.buttons.push({
            // Spread Provided Button Properties
            ...button,
            // Decide Visibility Depending On Whether Show Method Is Provided
            // Default: true
            text: button.text,
            class: className,
            show: button.show ? button.show(item, index) : true,
            disabled: button.disabled ? button.disabled(item, index) : false,
            row,
          });
        });

        return row;
      });

      return items;
    },
    rangeData(items) {
      if (!this.rangeable) {
        return items;
      }

      if (!this.dateRange.value.startDate || !this.dateRange.value.endDate) {
        return items;
      }

      var key;
      switch (this.rangeable.constructor) {
        case String:
          key = this.rangeable;
          break;
        case Object:
          key = this.rangeable.key;
          break;
      }

      var start = new Date(this.dateRange.value.startDate).getTime();
      var end = new Date(this.dateRange.value.endDate).getTime();
      items = items.filter((item) => {
        var column = item.row[key];
        if (!column) {
          return false;
        }
        var date = new Date(column).getTime();

        return start < date && date < end;
      });

      return items;
    },
    searchData(items) {
      var query = this.query;

      if (!query) {
        return items;
      }

      items = items.filter((item) => {
        var found = false;
        // Search In Mapped Data
        item.details.forEach((column) => {
          // Cancel If Original And Processed Value Are NULL
          if (!column.value || !column.rendered) {
            return;
          }
          // If Found In Original Value
          if (column.value.toString().match(new RegExp(query, "i"))) {
            found = true;
          }

          // If Found In Processed Value
          if (column.rendered.toString().match(new RegExp(query, "i"))) {
            found = true;
          }
        });

        // Search In Provided Data
        for (var column in item.row) {
          if (!item.row[column]) {
            continue;
          }

          if (item.row[column].toString().match(new RegExp(query, "i"))) {
            found = true;
          }
        }

        return found;
      });

      return items;
    },
    sortData(items) {
      var asc =
        this.lastSortColumn !== this.sortColumn
          ? true
          : this.asc === true
          ? false
          : true;
      var column = this.sortColumn;

      if (!column) {
        return items;
      }

      items = items.sort((a, b) => {
        var x, y;
        if (column === "#") {
          x = a.index;
        } else {
          var detailx = a.details.find((detail) => detail.name === column);
          if (typeof detailx.value === "number") {
            x = detailx.value;
          } else {
            x = detailx.rendered;
          }
          x = typeof x === "string" ? x.toLowerCase() : x;
        }

        if (column === "#") {
          y = b.index;
        } else {
          var detaily = b.details.find((detail) => detail.name === column);
          if (typeof detaily.value === "number") {
            y = detaily.value;
          } else {
            y = detaily.rendered;
          }
          y = typeof y === "string" ? y.toLowerCase() : y;
        }

        return x > y ? (asc ? 1 : -1) : asc ? -1 : 1;
      });

      this.asc = asc;

      return items;
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Navigation
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    navigate(page) {
      this.currentPage = page;
    },
    next() {
      this.currentPage =
        this.currentPage >= this.pages ? 0 : this.currentPage + 1;
    },
    prev() {
      this.currentPage =
        this.currentPage <= 0 ? this.pages : this.currentPage - 1;
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Events
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    clickedAction(action, index) {
      action.action(action.row.row, index);
    },
    clickedFilter(filter) {
      this.currentFilter = filter;
      if (this.ajaxPagination) {
        this.getData();
      }
    },
    clickedHeader(th) {
      if (!th.sortable) {
        return false;
      }
      this.lastSortColumn = this.sortColumn;
      this.sortColumn = th.name;
      this.renderData();

      if (this.ajaxPagination) {
        this.getData();
      }

      // if (this.sortColumn === lastColumn) {
      // 	this.asc = !this.asc;
      // }else {
      // 	this.asc = false;
      // }
    },
    clickedTD(td, index) {
      this.onClick ? this.onClick(td.row, td.value, td.name, index) : null;
      td.click ? td.click(td.row, td.value, td.name, index) : null;
    },
    DOMListener() {
      $(this.$el).on("input", ".datatable-search-input", (event) => {
        this.query = event.target.value;
        this.search(event);
      });

      $(this.$el).on("keyup", ".datatable-search-input", (event) => {
        if (event.which === 13) {
          this.searchEnterKey();
        }
      });

      $(this.$el).on("input", ".datatable-limit-input", (event) => {
        var value = parseInt(event.target.value);
        if (isNaN(value)) return false;

        this.itemsPerPage = value;
      });
    },
    searchEnterKey() {
      if (this.ajaxPagination) {
        this.getData();
      }
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/

    /*
			|-	----------------------------------------------------------------------------------------------------
			|	Notifications
			|-	----------------------------------------------------------------------------------------------------
			|
			*/
    error(message = "Data Loaded") {
      toastr.error(message);
    },
    success(message = "An Error Occured") {
      toastr.success(message);
    },
    /*
			|
			|-	----------------------------------------------------------------------------------------------------
			*/
  },
};
</script>

<style lang="sass">
@keyframes spin
	from
		transform: rotate(0deg)
	to
		transform: rotate(359deg)

.data-table
	// font-size: 14px
	&-loading
		align-items: center
		display: flex
		height: 200px
		flex-flow: column
		justify-content: center
		position: relative
		width: 100%
		&-spinner
			animation: spin 1s linear infinite
			border-radius: 999px
			border: 2px solid #007bff
			border-top-color: transparent
			content: ''
			height: 75px
			margin-bottom: 15px
			width: 75px
		&-text
			font-weight: 300
			text-trnasform: uppercase

	&-control
		.custom-select
			width: initial

	.table
		&-responsive
			min-height: 300px
			// margin-bottom: 30px
			// &::-webkit-scrollbar
			// 	-webkit-apperance: none
			// 	height: 15px
			// 	width: 15px
			// 	&-track
			// 		background: #eee
			// 		border-radius: 999px
			// 	&-thumb
			// 		background: #ccc
			// 		border-radius: 999px
			// 		border: 3px solid #eee
			// 		&:focus
			// 			background: #ccc
		&.straight
			white-space: nowrap
		thead
			th
				opacity: .5
				&.sortable
					cursor: pointer
					.sort-icon
						height: 10px
						margin-left: 5px
						// padding-right: 30px
						position: relative
						width: 4px
						&:before,
						&:after
							border: 1.8px solid transparent
							content: ''
							display: block
							left: 0
							opacity: .3
							position: absolute
							// right: 10px
						&:before
							border-bottom-color: currentColor
							top: 0px
							border-bottom-width: 3px
						&:after
							bottom: 0px
							border-top-color: currentColor
							border-top-width: 3px
				&.sort
					font-weight: 700
					opacity: 1
					&.asc
						.sort-icon
							&:before
								opacity: 1
					&.desc
						.sort-icon
							&:after
								opacity: 1
		tbody
			tr
				&.clickable
					cursor: pointer
			td
				// font-size: 12px

		&-filters
			margin-bottom: 15px

		&-filter
			background: #fff
			border-radius: 3px
			cursor: pointer
			color: #777
			display: inline-block
			font-size: 12px
			padding: 5px 15px
			margin: 0 0 3px 3px
			&:hover
				background: #aaa
				color: #fff
			&.active
				background: #337ab7
				color: #fff

	.filter-class
		@apply ml-3 bg-white shadow px-3 py-2 rounded-sm text-sm text-gray-700 mb-5 mt-2
		&:hover
			@apply bg-blue-500 text-white transform -translate-y-1 duration-200
		&.active
			@apply bg-blue-500 text-white
</style>
