<template>
	<nav class="navbar">

		<div class="sidebar-toggle md:hidden" @click.prevent="toggleSidebar">
			<div class="line"></div>
			<div class="line"></div>
			<div class="line"></div>
		</div>

		<template v-if="isBigScreen">
			<h1 class="text-xl font-bold">Good {{ timeOfDay }}, {{ firstName }}</h1>
		</template>

		<div class="hidden md:flex flex-row items-center" v-else-if="firstNotification">
			<div class="bg-blue-200 rounded-full text-sm w-5 h-5 text-center mr-2">
				!
			</div>
			<div class="text-xs font-normal opacity-50 text-gray-600">{{ firstNotification.message }}</div>
		</div>

		<ul class="flex flex-row items-center ml-auto">
			<!-- <li class="h-full dropdown">
				<a href="#!" class="relative flex items-center px-5 py-3" dropdown>
					<span class="relative">
						<ion-icon name="square-outline" class="text-xl" />
						<span class="block absolute -mr-1 -mt-1 bg-red-500 text-white rounded-full right-0 top-0 text-xxs text-center flex items-center justify-center" style="height: .74rem; width: .74rem">2</span>
					</span>
				</a>
				<ul class="dropdown-menu min-w-400px left">
					<li class="px-10 pt-5 pb-5 border-b border-blue-100 text-black">
						Notifications
					</li>
					<a href="#!" class="px-10 py-4 text-xs text-gray-700 hover:bg-blue-100 relative border-b border-blue-100">
						<span>Alias dolor itaque maiores error minima similique, vel facere, obcaecati officiis deleniti?</span>
					</a>
					<a href="#!" class="px-10 py-4 text-xs text-gray-700 hover:bg-blue-100 relative border-b border-blue-100">
						<span>Exercitationem aperiam, blanditiis vero aspernatur, soluta aut.</span>
						<span class="absolute left-5 w-5px h-5px rounded-lg bg-red-500 top-1/2 -mt-3px"></span>
					</a>
					<a href="#!" class="px-10 py-4 text-xs text-gray-700 hover:bg-blue-100 relative border-b border-blue-100">
						<span>Iusto, recusandae, pariatur dolores assumenda rem deleniti earum nostrum autem cumque.</span>
						<span class="absolute left-5 w-5px h-5px rounded-lg bg-red-500 top-1/2 -mt-3px"></span>
					</a>
					<li class="flex items-center justify-center py-4">
						<router-link :to="{name: 'notifications'}" class="text-sm inline-flex items-center">
							View All 
							<span class="caret caret-xs caret-right ml-2"></span>
						</router-link>
					</li>
				</ul>
			</li> -->

			<!-- <label
				class="flex flex-row items-center cursor-pointer py-2 px-4 border border-blue-200 rounded"
				style="user-select: none"
				v-if="canToggle"
			>
				<span class="text-xs text-gray-500 mr-3">
					<span class="duration-300" :class="{ 'text-sm font-bold text-black': staff_account_type == 'business' }">Business</span>
					/
					<span class="duration-300" :class="{ 'text-sm font-bold text-black': staff_account_type == 'personal' }">Personal</span>
				</span>
				<label class="custom-switch">
					<input type="checkbox" class="custom-switch-input" :checked="staff_account_type === 'personal'" @change="toggleAccountType">
					<span class="custom-switch-label"></span>
				</label>
			</label> -->

			<li class="h-full dropdown">
				<!-- <a href="#!" class="relative flex items-center px-5 py-2 rounded" dropdown>
					<span class="flex items-center justify-center h-30px w-30px bg-blue-800 text-white rounded-full mr-2 text-xs">{{ firstLetter }}</span>
					<span class="text-xs mr-2">{{ user && user.name }}</span>
					<span class="text-xs text-gray-600 caret caret-sm"></span>
				</a> -->
				<a href="#!" class="relative flex items-center px-5 py-2 rounded-4xl border" dropdown>
					<span class="flex items-center justify-center h-30px w-30px bg-blue-800 text-white rounded-full mr-2 text-xs">{{ firstLetter }}</span>
					<span class="text-xs mr-2">{{ user && user.first_name }} {{ user && user.last_name }}</span>
					<span class="text-xs text-gray-600 caret caret-sm"></span>
				</a>
				<ul class="dropdown-menu right-0 mt-2" @click="closeDropdown">
					<!-- <a href="#!" class="dropdown-menu-item">
						<span class="icon"></span>
						<span class="text">Sample Text</span>
					</a>
					<a href="#!" class="dropdown-menu-item">
						<span class="icon"></span>
						<span class="text">Sample Text</span>
					</a>
					<a href="#!" class="dropdown-menu-item">
						<ion-icon name="help-outline" class="icon"></ion-icon>
						<span class="text">FAQs</span>
					</a>
					<a href="#!" class="dropdown-menu-item">
						<ion-icon name="reader-outline" class="icon"></ion-icon>
						<span class="text">Terms & Conditions</span>
					</a> -->
					<router-link
						:to="{ name: 'dashboard'}"
						class="dropdown-menu-item text-blue-500"
					>
						<ion-icon name="log-out-outline" class="icon"></ion-icon>
						<span class="text">dashboard</span>
					</router-link>
					<a href="#!" class="dropdown-menu-item text-red-500" @click.prevent="logout">
						<ion-icon name="log-out-outline" class="icon"></ion-icon>
						<span class="text">Log Out</span>
					</a>
				</ul>
			</li>
		</ul>

	</nav>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	import $ from 'jquery';

	const notifications = [
		// {
		// 	message: 'Your company has been registered, you can add some staff members',
		// 	status: 'neutral',
		// 	test() {
		// 		return !this.user.email_verified_at
		// 	}
		// },
		// {
		// 	message: 'Your account was sucessfuly created. Kindly verify your email address.',
		// 	status: 'neutral',
		// 	test() {
		// 		return !this.user.email_verified_at
		// 	}
		// },
	];

	export default {
		computed: {
			...mapState('design', ['sidebar']),
			...mapState('session', ['staff_account_type', 'corporate_card_count']),
			notifications() {
				return notifications.filter( notification => {
					return notification.test.apply(this);
				} );
			},
			firstNotification() {
				return this.notifications[0];
			},
			canToggle() {
				return this.accountType == this.ENV_VUE_APP_CUSTOMER_PATH && this.user.company_id;
			}
		},
		methods: {
			...mapMutations('design', ['toggleSidebar']),
			closeDropdown(event) {
				console.log($(event.target).closest('.dropdown'));
				$(event.target).closest('.dropdown').removeClass('open').find('.dropdown').removeClass('top bottom')
			},
			logout() {
				this.$store.dispatch('session/logout');
				this.$router.push({ name: 'login' });
			},
			toggleAccountType() {
				this.$store.dispatch('session/updateStaffAccountType', this.staff_account_type == 'personal' ? 'business' : 'personal');
			}
		}
	}
</script>