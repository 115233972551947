import Vue from 'vue';
import pages from '@/config/pages';

Vue.mixin({
  allroles: {
    credpal: [
      'super', 'support', 'finance', 'risk', 'business', 'sales'
    ],
    company: [
      'super', 'hr', 'finance', 'audit'
    ],
    staff: [
      'staff', 'department_head', 'unit_head'
    ]
  },
  methods: {
    canAccess(pageName) {
      var page;

      switch (pageName?.constructor) {
        case String:
          page = pages[pageName];
          if (!page) {
            return false;
          }

          return page.permissions.some( permission => permission === '*' || this.canDo(permission) );
        case Array:
          return pageName.some( page => this.canAccess(page) );
        default:
          console.warn(`Invalid Parameter Type Passed To canAccess Method - Expected Parameters Array, String - ${pageName?.constructor?.toString() || null} Passed`);
          break;
      }
    },
    canDo(permission) {
      const permissions = this.$store.state.session.permissions;

      return permissions.indexOf(permission) > -1;
    },
  }
});