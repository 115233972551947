<template>
	<div id="app" :class="{'sidebar-open': sidebar.open, [`${this.$route.name}-page`]: true}">
		<transition>
			<transition name="fade" mode="out-in">
				<router-view/>
			</transition>
		</transition>

		<pre-loader />

	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		components: {
			PreLoader: require('@/components/loaders/PreLoader.vue').default
		},
		computed: {
			...mapState('design', ['sidebar']),
		}
	}
</script>