function resource(api, data = [], config = {}, argument, autoload = true) {

  var resource = {
    _api: api,
    _autoload: autoload,
    _argument: argument,
    _lastUpdated: null,
    loading:  false,
    loaded:  false,
    error:  null,
    success:  false,
    key: 'data',
    ...config,
    _originalData: data,
    data: data
  };

  return resource;
}

export default {
  namespaced: true,
  state: {
    admin: {
      companies: resource('admin.companies.all'),
    },
    corporate: {
      activities: resource('corporate.activities.recent'),
      admins: resource('corporate.admins.all'),
      staff: resource('corporate.staff.all'),
      groups: resource('corporate.groups.all', [], { key: 'groups' }),
      cards: resource('corporate.cards.all'),
      wallet: resource('corporate.wallet.get', {}, {}, function() { return this.user.company_id }),
      plan: resource('corporate.subscription.getActivePlan', {}, {}, function() { return this.user.company_id }),
      policies: resource('corporate.expensePolicies.all', [], {}, function() { return this.user.company_id }),
    },
    customer: {},
  },
  getters: {},
  mutations: {
    save(state, { mode, data = true, resourceName }) {
      var resource = state[mode]?.[resourceName];
      resource.data = data;
      resource._lastUpdated = new Date();
    },
    loading(state, { mode, status = true, resourceName }) {
      var resource = state[mode]?.[resourceName];
      resource.loading = status;
    },
    error(state, { mode, error, resourceName }) {
      var resource = state[mode]?.[resourceName];
      resource.error = error;
    },
  },
  actions: {
    fetchAll({ state, dispatch }, mode) {

      if (!mode) {
        console.warn('No Mode Set For Resources');
        return false;
      }

      const resources = state[mode];

      if (!resources) {
        throw new Error(`No resources in ${mode}`);
      }

      for (let resourceName in resources) {
        dispatch('fetch', { resourceName, mode, autoload: true, forced: false });
      }

    },
    async fetch({ commit, state }, { mode, resourceName, autoload = false, forced = true }) {
      const request = state[mode][resourceName];

      if (!request) {
        throw new Error(`Unknown Resource ${resourceName}`);
      }

      if (autoload && !request._autoload) {
        return false;
      }

      if (!forced) {
        const _lastUpdated = request._lastUpdated;
        if (_lastUpdated) {
          if ((new Date).getTime() - _lastUpdated.getTime() <= 300000) {
            return false;
          }
        }
      }

      commit('loading', { mode, resourceName, status: true });

      var args = [request._api];

      if (request._argument) {
        args.push(request._argument.apply(window.App));
      }

      args.push({
        error: error => {
          console.log(error);
          commit('error', { mode, resourceName, error });
        }
      });

      var { response } = await window.App.sendRequest(...args);
      var data;
      if (request.key) {
        data = response.data[request.key];
      }else {
        data = response.data;
      }

      commit('save', { mode, resourceName, data });

      commit('loading', { mode, resourceName, status: false });
    }
  }
}