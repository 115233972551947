<template>
  <div class="-mx-10 -mt-8 h-screen">
    <div class="border-t border-blue-200 h-full mt-2">
      <dailySummary />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    dailySummary: require("./DailySummary").default,
  },
};
</script>
